import { NODES_TYPE_PARENT, NODES_TYPE_FIELDS, NODES_TYPE_GAP, NODES_TYPE_BY, NODES_TYPE_DATASOURCE, NODES_TYPE_TRAVERSER, NODES_TYPE_TEXTBLOCK } from './../../../shared/api/nodes/nodes.models';
import { EventEmitter, Injectable } from '@angular/core';
import { isArray, isNull, isNullOrUndefined, isNumber, isString, isUndefined } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { List, Map, OrderedMap, Set } from 'immutable';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

import {
  CoreFilter,
  CoreGroup,
  CoreHumanResource, CoreLevel,
  CoreMultiTransfer,
  CoreNodeTypes,
  CoreStatus, CoreStatusItem,
  CoreTransfer, CoreWidgetMessage,
  CoreWidgets,
  TreeActivity,
  TreeNode,
  TreeRelationship
} from '../../../core/interface/core.interface';
import {
  NodeCreate,
  NODES_TYPE_CAPACITY,
  NODES_TYPE_CHILD, NODES_TYPE_COLUMN, NODES_TYPE_COMMENT, NODES_TYPE_CONNECT, NODES_TYPE_FORM,
  NODES_TYPE_FORM_OPTION,
  NODES_TYPE_GROUP,
  NODES_TYPE_HUMANRESOURCE, NODES_TYPE_MACHINE_TYPE, NODES_TYPE_MODEL, NODES_TYPE_NODETYPEGROUP,
  NODES_TYPE_REMINDER,
  NODES_TYPE_REQUIREMENT, NODES_TYPE_SHARE, NODES_TYPE_SETTINGS
} from '../../../shared/api/nodes/nodes.models';
import { Datum } from '../../../shared/utilities/datum';
import { CoreTransformer } from '../../../core/transformer/core.transformer';
import { Activity } from '../../../shared/api/activities';
import { ActivityRelationships } from '../../../shared/api/activities/activities.models';
import { FormElement, FormEntry, FormInterface, FormOption, FormResult } from '../interface/form.interface';
import { PayloadService } from '../../../services/payload/payload.service';
import { IPayload } from '../../../services/payload/payload.interface';
import { environment } from '../../../../environments/environment';
import { HumanResource } from '../../../shared/api/humanresources';
import { CoreService } from '../../../core/service/core.service';
import { TableColumn, TableConfigurationOptions } from '../../table/interface/table.interface';
import { EisenhowerService } from '../../eisenhower/service/eisenhower.service';
import { Group, RelationshipCreate } from '../../../shared';
import { CoreUtilities } from '../../../core/utilities/core.utilities';
import { AppGlobal } from '../../../app.global';
import { SubscriptionService } from '../../../shared/utilities/subscription';
import { FormTableModalComponent } from '../modal/form.table.modal';
import { FormWidgetFormElement } from '../../../widgets/form/interface/form.widget.interface';
import { ActionButtonsService } from '../../../services/action-buttons/action-buttons.service';
import { NotificationsService } from '../../notifications/service/notifications.service';
import {
  ColorlabelproviderServiceStatusfield
} from '../../../services/colorlabelprovider/providers/colorlabelprovider.service.statusfield';
import { DynamicFormComponent } from '../../../shared/form/dynamic-form/dynamic-form.component';
import { RELATIONSHIP_TYPE_DEFAULT } from '../../../shared/api/relationships/relationships.models';
import { Traverser } from '../../../services/traverser/traverser';
import { CalculationService } from '../../../services/calculation/calculation.service';
import { AnswerSelectElement } from '../entries/answer-select/answer-select.component';

@Injectable()
export class FormService {

  private humanResourcesMap = OrderedMap<string, CoreHumanResource>();
  private marketsMap = OrderedMap<string, TreeNode>();
  private matrixMap = OrderedMap<string, TreeNode>();

  public coreService: CoreService;
  public eisenhowerService: EisenhowerService;
  public actionButtonsService: ActionButtonsService;
  public notificationsService: NotificationsService;

  public documentsToLoad: string[] = [];

  public fieldNodeCache = Map<string, CoreFilter[]>();

  protected additionalInfos = {};

  private subscriptionService = new SubscriptionService();

  public constructor(private translateService: TranslateService, private coreTransformer: CoreTransformer, private coreUtilities: CoreUtilities, private appGlobal: AppGlobal, private calculationService: CalculationService) {
    this.calculationService.formService = this;
    this.coreTransformer.formService = this;
  }

  public setActionButtonService() {
    this.actionButtonsService.formService = this;
    this.actionButtonsService.coreService = this.coreService;
    this.actionButtonsService.notificationsService = this.notificationsService;
  }

  public setHumanResources(humanResourcesMap: OrderedMap<string, CoreHumanResource>) {
    this.humanResourcesMap = humanResourcesMap;
  }

  public setMarkets(marketsMap: OrderedMap<string, TreeNode>) {
    this.marketsMap = marketsMap;
  }

  public setAdditionalInfos(additionalInfos: any) {
    this.additionalInfos = additionalInfos;
    return this;
  }

  public getCoreTransferFromFormResult(formResult: FormResult, form: FormInterface, treeNode: TreeNode | TreeActivity, create = false, forceCreate = false, updateGroups = true): CoreMultiTransfer {
    let transfers: CoreMultiTransfer = {
      create: <CoreTransfer> { modelId: (<TreeNode>treeNode).modelId, nodes: [], relationships: [], activities: [], nodeStructures: [], ids: [], groups: []},
      update: <CoreTransfer> { modelId: (<TreeNode>treeNode).modelId, nodes: [], relationships: [], activities: [], nodeStructures: [], groups: [] },
      delete: <CoreTransfer> { modelId: (<TreeNode>treeNode).modelId, nodes: [], relationships: [], activities: [], groups: [] }
    };

    const _treeNode = treeNode;
    let buckets = Map<string, TreeActivity>();
    let nodeDelta = Map<string, Map<string, any>>();
    let nodeStructureDelta = Map<string, Map<string, any>>();
    let activityDelta = Map<string, Map<string, any>>();
    let bucketDelta = Map<string, Map<string, Map<string, any>>>();

    const addResponsibleIds = [];
    let removeResponsibleIds = [];

    let coefficientCreateTreeNode: TreeNode;

    /* Get the entries map for form */
    const entriesMap = this.getEntriesKeyMap(form.tabs);
    /* Iterate over delta */
    formResult.delta.forEach((delta, key) => {
      /* Get entry */
      const entry = entriesMap.get(key);
      if (!isNullOrUndefined(entry)) {

        /* Set entry node */
        let entryTreeNode = treeNode;

        /* Check if it's a node type node */
        if (!isNullOrUndefined(entry.sourceNodeType)) {
          const sourceNode = this.coreService.searchBy({
            filters: [{
              by: 'nodeType',
              value: entry.sourceNodeType
            }]
          }, (<TreeNode>treeNode).children, true, 'children', true)[0];
          if (!isNullOrUndefined(sourceNode)) {
            entryTreeNode = sourceNode;
          }
        }
        /* In case we have an activity being selected */
        if (entryTreeNode.internalType === 'treeActivity') {
          /* Set the value */
          activityDelta = activityDelta.set(entryTreeNode.id, (activityDelta.has(entryTreeNode.id) ? activityDelta.get(entryTreeNode.id) : Map<string, any>()).set(key.split(':')[0], delta));
        } else if (entry.nodeBucket && entry.controlType !== 'text-marker') {
          const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
          /* Get the bucket */
          let bucket = this.getBucket(<TreeNode>entryTreeNode, entry.entryNode.formFieldBucketId);
          if (bucket.nodeData === undefined) {
            bucket.nodeData = (treeNode as TreeNode).dataId;
          }
          /* Get the bucket from buckets */
          if (buckets.has(bucket.id)) {
            bucket = buckets.get(bucket.id);
          }
          /* Set the bucket delta */
          const d1 = bucketDelta.has(id) ? bucketDelta.get(id) : Map<string, Map<string, any>>();
          const d2 = d1.has(bucket.id) ? d1.get(bucket.id) : Map<string, any>();
          bucketDelta = bucketDelta.set(id, d1.set(bucket.id, d2.set(key.split(':')[0], delta)));
          /* Store the bucket */
          buckets = buckets.set(bucket.id, bucket);
        } else {
          const keys = key.split(':');
          switch (keys[0]) {
            case 'odin-factsheet': {
              break;
            }
            case 'coefficient': {
              /* Get related tree node */
              if ((treeNode as TreeNode).relatedTreeNode !== undefined) {
                /* Add to transfer */
                if ((treeNode as TreeNode).relatedTreeNode[keys[1]] !== delta) {
                  (transfers.update.nodes as IPayload[]).push({ id: (treeNode as TreeNode).relatedTreeNode.dataId, data: Map().set(keys[1], delta) } as IPayload);
                }
              } else {
                /* Variables */
                let nodeType: number;
                let fields = [];

                /* Amount of elements to be created */
                const amount = entry.entryNode.budget === undefined || entry.entryNode.budget === null || entry.entryNode.budget === 0 ? 1 : entry.entryNode.budget;

                /* Iterate over the children */
                const children = entry.entryNode.children;
                const childrenCount = children.length;
                for (let i = 0; i < childrenCount; i++) {
                  const child = children[i];
                  switch (child.nodeType) {
                    case NODES_TYPE_NODETYPEGROUP:
                      nodeType = child.children.map(c => c.nodeType)[0];
                      break;
                    case NODES_TYPE_FIELDS:
                      fields = child.children.map(c => c.formFieldId);
                      break;
                  }
                }

                /* Count the fields */
                const fieldsCount = fields.length;

                /* Create the children */
                for (let i = 0; i < amount; i++) {

                  /* Name */
                  let name = entry.entryNode.formId;
                  if (name.indexOf('{count}') !== -1) {
                    /* Get all children from same node type */
                    const d = (treeNode as TreeNode).unfilteredChildren.filter(c => c.nodeType === nodeType).length;
                    name = name.replace('{count}', '' + (d + 1 + i));
                  }

                  if (name.indexOf('{name}') !== -1) {
                    name = name.replace('{name}', treeNode.name);
                  }

                  /* The node */
                  const addNode = { id: UUID.UUID(), nodeType, name } as TreeNode;
                  coefficientCreateTreeNode = addNode;

                  /* The fields to be copied over */
                  for (let i2 = 0; i2 < fieldsCount; i2++) {
                    const field = fields[i2];
                    addNode[field] = treeNode[field];
                  }

                  /* Add the coefficient */
                  addNode[keys[1]] = delta;

                  /* Add to transfer */
                  (transfers.create.nodes as TreeNode[]).push(addNode);
                  (transfers.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: treeNode.id, childId: addNode.id, weight: delta } as TreeRelationship);
                }
              }
              break;
            }
            case 'checkChildren': {
                /* Set the value */
                const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
                const formEntryNode = form.tabs[0].children[0].entry.entryNode;
                const mirrorNode = formEntryNode.children.filter(e => e.nodeType !== NODES_TYPE_SETTINGS)[0];
                const children =  (<TreeNode> entryTreeNode).children.filter(e => e.nodeType === mirrorNode.nodeType && e.crossReference === mirrorNode.crossReference);

                const settingsNode = formEntryNode.children.filter(e => e.nodeType === NODES_TYPE_SETTINGS);
                const settings = {
                  name: '',
                  count: false,
                  parentNodes: [],
                  startDateInput: '',
                  endDateInput: '',
                  startDate: null,
                  endDate: null,
                  amount: parseInt(formResult.delta.get(key)),
                  dates: [],
                  minDistance: null,
                };
                if (settings.amount < 0) {
                  break;
                }
                if (settingsNode.length > 0) {
                  const node = settingsNode[0];
                  const countA = node.children.length;
                  for (let i = 0; i < countA; i++) {
                    const setting = node.children[i];
                    switch (setting.nodeType) {
                      /* Additional grouped parents which should be connected to the created Nodes */
                      case NODES_TYPE_PARENT:
                        const newParents = setting.children;
                        const paCount = newParents.length;
                        for (let b = 0; b < paCount; b++) {
                          const parent = newParents[b];
                          settings.parentNodes.push(AppGlobal.treeNodes.get(parent.nodestructures.filter(e => e.split(':')[0] === '' + (<TreeNode> entryTreeNode).modelId)[0].split(':')[1]));
                        }
                        break;
                      /* minimal amount of days in between Dates */
                      case NODES_TYPE_GAP:
                        settings.minDistance = setting.budget;
                        break;
                      case NODES_TYPE_FIELDS:
                        const fields = setting.children;
                        const countF = fields.length;
                        for (let b = 0; b < countF; b++) {
                          const field = fields[b];
                          switch (field.formFieldId) {
                            /* How to call the created Node */
                            case 'name':
                              const reg = new RegExp(/({{)(.*?)(}})/gm);
                              let newName = field.name;
                              let sol = reg.exec(newName);
                              while (sol !== null) {
                                if (sol[2] !== 'count') {
                                  newName = newName.replace('{{' + sol[2] + '}}', (<TreeNode> entryTreeNode)[sol[2]]);
                                } else {
                                  settings.count = true;
                                  newName = newName.replace('{{count}}', '{.{x}.}');
                                }
                                sol = reg.exec(newName);
                              }
                              settings.name = newName;
                              break;
                            case 'end':
                              /* EndDate of period */
                              settings.endDate =  moment((<TreeNode> entryTreeNode)[field.children[0].formFieldId]);
                              /* Where to store the date */
                              settings.endDateInput = field.children[0].children[0].formFieldId;
                              break;
                            case 'startDate':
                              /* startDate of period */
                              settings.startDate =  moment((<TreeNode> entryTreeNode)[field.children[0].formFieldId]);
                              /* Where to store Startdate */
                              settings.startDateInput = field.children[0].children[0].formFieldId;
                              break;
                          }
                        }
                        break;
                    }
                  }
                }

                /* Set dates */
                if (settings.endDate !== undefined && settings.endDate !== null && settings.startDate !== undefined && settings.startDate !== null) {
                  /* Get amount of working days */
                  const workingDays = settings.endDate.diff(settings.startDate, 'days') + 1;
                  /* calculate distance between days to spread evenly */
                  let daysBetween = (Math.floor(workingDays / settings.amount));
                  if (settings.minDistance !== null && settings.minDistance > daysBetween) {
                    daysBetween = settings.minDistance;
                  }

                  for (let i = 0; i < settings.amount; i++) {
                    const date = (i === 0) ? settings.startDate.clone() : settings.dates[i - 1].clone();
                    if (i === 0) {
                      if (date.isoWeekday() === 6) {
                        date.add(2, 'days');
                      } else if (date.isoWeekday() === 7) {
                        date.add(1, 'days');
                      }
                      settings.dates.push(date);
                      continue;
                    }
                    date.add((daysBetween === 0) ? 1 : daysBetween, 'days');
                    /* Saturday */
                    if (date.isoWeekday() === 6) {
                      /* put friday */
                      date.add(-1, 'days');
                      if (date._i === settings.dates[i - 1]._i || date.diff(settings.dates[i - 1], 'days') < settings.minDistance) {
                        /* put next monday */
                        date.add(3, 'days');
                      }


                    } else if (date.isoWeekday() === 7) {

                      /* Sunday -> put monday */
                      date.add(1, 'days');
                    }
                    /* Don't pass the enddate of the interval */
                    if (settings.endDate.diff(date, 'days') <= 0) {
                      settings.dates.push(settings.endDate);
                    } else {
                      settings.dates.push(date);
                    }
                  }
                }

                /* update the certain Field */
                nodeDelta = nodeDelta.set(id, (nodeDelta.has(id) ? nodeDelta.get(id) : Map<string, any>()).set(keys[1], delta));
                transfers.update.modelId = (<TreeNode> entryTreeNode).modelId;
                const currentAmount = children.length;
                if (settings.amount > currentAmount) {
                  /* Number of children increased */
                  const toAdd =  settings.amount - currentAmount;
                  /* Add new nodes */
                  for (let i = 0; i < toAdd; i++) {
                    const newNode: TreeNode = <TreeNode> {
                      id: UUID.UUID(),
                      name: ( settings.name !== '') ? settings.name : mirrorNode.name,
                      nodeType: mirrorNode.nodeType,
                      modelId: (<TreeNode> entryTreeNode).modelId,
                      crossReference: mirrorNode.crossReference
                    };
                    if (settings.count) {
                      newNode.name = newNode.name.replace('{.{x}.}', (i + 1) + '');
                    }
                    if (settings.dates.length > 0) {
                      newNode[settings.startDateInput] = settings.dates[i]._d;
                      newNode[settings.endDateInput] = settings.dates[i]._d;
                    }
                    (<TreeNode[]> transfers.create.nodes).push(newNode);
                    if (settings.parentNodes.length > 0) {
                      (transfers.create.relationships as TreeRelationship[]) = (transfers.create.relationships as TreeRelationship[]).concat(settings.parentNodes.map(parent => ({ id: UUID.UUID(), parentId: parent.id, childId: newNode.id, modelId: newNode.modelId } as TreeRelationship)));
                    }
                    (transfers.create.relationships as TreeRelationship[]).push({ id: UUID.UUID(), parentId: (entryTreeNode as TreeNode).id, childId: newNode.id, modelId: newNode.modelId } as TreeRelationship);
                  }
                  /* Update already existing nodes with new Dates */
                  if (settings.dates.length > 0) {
                    for (let i = 0; i < currentAmount; i++) {
                      const currentChild = children[i];
                      let data = Map().set(settings.startDateInput, settings.dates[i + toAdd]._d).set(settings.endDateInput, settings.dates[i + toAdd]._d);
                      if (settings.count) {
                        data = data.set('name', settings.name.replace('{.{x}.}', (i + 1 + toAdd) + ''));
                      }
                      (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: currentChild.dataId, data: data});
                    }
                  }
                } else if (settings.amount < currentAmount) {
                  /* Number of children decreased */
                  const toRemove = currentAmount - settings.amount;
                  /* Remove children */
                  for (let i = 0; i < toRemove; i++) {
                    const child = children[i];
                    const relation = this.coreService.getInstantRelationships({ filters: [{ by: 'childId', value: child.id }/* , { by: 'parentId', value: (<TreeNode> entryTreeNode).id } */] });
                    (<TreeNode[]> transfers.delete.nodes).push(child);
                    (<TreeRelationship[]> transfers.delete.relationships).push(relation[0]);
                  }
                  /* Update already existing nodes with new Dates */
                  if (settings.dates.length > 0) {
                    for (let i = toRemove; i < currentAmount; i++) {
                      const currentChild = children[i];
                      let data = Map().set(settings.startDateInput, settings.dates[i - toRemove]._d).set(settings.endDateInput, settings.dates[i - toRemove]._d);
                      if (settings.count) {
                        data = data.set('name', settings.name.replace('{.{x}.}', (i + 1 - toRemove) + ''));
                      }
                      (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: currentChild.dataId, data: data});
                    }
                  }
                }
                break;
              }
            case 'text-marker': {
              const snippetFormEntryNode = form.tabs[0].children.filter(child => child.entry.controlType === 'text-marker')[0].entry.entryNode.children.filter(e => e.nodeType !== NODES_TYPE_SETTINGS)[0];
              const settingsNode = form.tabs[0].children.filter(child => child.entry.controlType === 'text-marker')[0].entry.entryNode.children.filter(e => e.nodeType === NODES_TYPE_SETTINGS);
              const settings = {
                model: null,
              };
              if (settingsNode.length > 0) {
                const countI = settingsNode[0].children.length;
                for (let i = 0; i < countI; i++) {
                  const setting = settingsNode[0].children[i];
                  switch (setting.nodeType) {
                    case NODES_TYPE_MODEL:
                      settings.model = this.coreService.getInstantModelByModelNode(setting)[0].get('id');
                      break;
                    default:
                      console.warn('Not Configurable like this');
                      break;
                  }
                }
              }
              let groupNode = null;
              if (!isNullOrUndefined(settings.model)) {
                const groupedNodes = AppGlobal.treeNodes.get((<TreeNode> entryTreeNode).id).nodestructures.filter(e => e.split(':')[0] === '' + settings.model);
                if (groupedNodes.length > 0) {
                  groupNode = <TreeNode> {
                    id: groupedNodes[0].split(':')[1],
                  };
                } else {
                  groupNode = <TreeNode> {
                    id: UUID.UUID(),
                    dataId: (<TreeNode> entryTreeNode).dataId,
                    originalId: null,
                    modelId: settings.model
                  };
                  transfers.create.modelId = settings.model;
                  (<TreeNode[]> transfers.create.nodeStructures).push(groupNode);
                }

              }
              const snippetName = formResult.formGroup.value['snippet-name:' + entry.key];
              if (!isNullOrUndefined(snippetFormEntryNode) && keys[0] === 'text-marker' && formResult.formGroup.value[key] !== '') {
                const snippetFormId = snippetFormEntryNode.formFieldId;
                  /* Store snippet on a different node */
                const snippetNode: TreeNode = <TreeNode> {
                  id: UUID.UUID(),
                  name: snippetName || 'snippet:' + entryTreeNode.name,
                  nodeType: snippetFormEntryNode.nodeType,
                  modelId: (isNullOrUndefined(settings.model)) ? (<TreeNode> entryTreeNode).modelId : settings.model //
                };
                snippetNode[snippetFormId] = formResult.formGroup.value[key];
                (<TreeNode[]> transfers.create.nodes).push(snippetNode);
                (<RelationshipCreate[]> transfers.create.relationships).push(new RelationshipCreate({ id: UUID.UUID(), parent: (isNullOrUndefined(groupNode)) ? (<TreeNode> entryTreeNode).id : groupNode.id, child: snippetNode.id, model: snippetNode.modelId }));

              }
              break;
            }
            case 'dynamic-reminder':
              if (!isNullOrUndefined(delta) && delta !== '') {
                if (!isNullOrUndefined(delta.reminder)) {
                  const mom = moment(delta.value).format('YYYY-MM-DDTHH:mm:ss');
                  const data1 = Map().set('targetDate', mom);
                  if (!isNullOrUndefined(delta.reminder) && !isNullOrUndefined(delta.reminder.dataId)) {
                    (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: delta.reminder.dataId, data: data1 });
                  } else {
                    (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> new NodeCreate(data1.toJS()).set('name', 'reminder-' + mom).set('nodeType', NODES_TYPE_REMINDER).set('id', delta.reminder.id));
                    (<RelationshipCreate[]> transfers.create.relationships).push(new RelationshipCreate({ id: UUID.UUID(), parent: delta.element.id, child: delta.reminder.id }));
                  }
                } else {
                  const mom = moment(delta).format('YYYY-MM-DDTHH:mm:ss');
                  const data1 = Map().set('targetDate', mom);
                  const uuid = UUID.UUID();
                  (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> new NodeCreate(data1.toJS()).set('name', 'reminder-' + mom).set('nodeType', NODES_TYPE_REMINDER).set('id', uuid));
                  (<RelationshipCreate[]> transfers.create.relationships).push(new RelationshipCreate({ id: UUID.UUID(), parent: treeNode.id, child: uuid }));
                }
              }
              break;
            case 'comments':
            case 'dynamic-comments':
              /* Create a comment node */
              if (delta !== undefined && delta !== null && delta !== '') {
                if (!isArray(delta)) {
                  delta['positionX'] = (<TreeNode> treeNode).positionX;
                  const id = UUID.UUID();
                  const commentNode = new NodeCreate(delta).set('id', id) as NodeCreate;
                  const commentRelationship = new RelationshipCreate({
                    id: UUID.UUID(),
                    weight: 1,
                    type: RELATIONSHIP_TYPE_DEFAULT,
                    parent: _treeNode.id,
                    child: id,
                    model: delta.modelId
                  });
                  (<NodeCreate[]> transfers.create.nodes).push(commentNode);
                  (<RelationshipCreate[]> transfers.create.relationships).push(commentRelationship);
                }
              }
              break;
            case 'dynamic-connect':
              const connected: AnswerSelectElement[] = delta.selected;
              const connectedIds = connected.map(e => e.connectedWith.id);
              const initialConnected: AnswerSelectElement[] = delta.initialSelected;
              const notConnected = isNullOrUndefined(initialConnected) ? [] : initialConnected.filter(d => connectedIds.indexOf(d.connectedWith.id) === -1);

              /* Iterate over connected nodes */
              let count = connected.length;
              for (let i = 0; i < count; i++) {
                const connect = connected[i];
                /* Check if a connection node is there */
                if (connect.connectionNode.phantom === true) {
                  /* Create the connection node */
                  (transfers.create.nodes as TreeNode[]).push(connect.connectionNode);
                  /* Link the connection node */
                  transfers.create.relationships.push({ id: UUID.UUID(), parentId: treeNode.id, childId: connect.connectionNode.id, modelId: connect.connectionNode.modelId } as any);
                  transfers.create.relationships.push({ id: UUID.UUID(), parentId: connect.connectedWith.id, childId: connect.connectionNode.id, modelId: connect.connectionNode.modelId } as any);
                } else if (connect.delta !== undefined) {
                  /* Update the connection node */
                  (transfers.update.nodes as IPayload[]).push({ id: connect.connectionNode.dataId, data: connect.delta } as IPayload);
                }
              }

              /* Disconnect nodes */
              count = notConnected.length;
              for (let i = 0; i < count; i++) {
                const notConnect = notConnected[i];
                /* Remove node */
                (<TreeNode[]> transfers.delete.nodes).push(notConnect.connectionNode);
                /* Remove relationship */
                const treeRelationships = this.coreService.getInstantRelationships({ filters: [{ by: 'childId', value: notConnect.connectionNode.id }] }).concat(this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: notConnect.connectionNode.id }] }));
                const count2 = treeRelationships.length;
                for (let i2 = 0; i2 < count2; i2++) {
                  (<TreeRelationship[]> transfers.delete.relationships).push(treeRelationships[i2]);
                }
              }
              break;
            case 'dynamic-children':
            case 'dynamic-parents':
            case 'dynamic-reassign':
              const data2: any = delta;
              /* Transfer */
              if (delta.nodes !== undefined && delta.type !== 'update') {
                /* Already transfer */
                transfers.create.nodes = (transfers.create.nodes as TreeNode[]).concat((delta.nodes as TreeNode[]));
                transfers.create.relationships = (transfers.create.relationships as TreeNode[]).concat((delta.relationships as TreeNode[]));
                transfers.create.modelId = delta.modelId;
                break;
              } else if (delta.nodes !== undefined && delta.type === 'update') {
                transfers.update.nodes = (transfers.update.nodes as TreeNode[]).concat((delta.nodes as TreeNode[]));
                break;
              }
              if (isNullOrUndefined(data2) || data2 === '') {
                break;
              }
              /* Data */
              const treeNodeElement = <TreeNode> data2.element;
              const selectedTreeNodes = <TreeNode[]> data2.selected;
              const selectedTreeNodeIds = !isNullOrUndefined(selectedTreeNodes) ? selectedTreeNodes.map(d => d.id) : [];
              const initialSelected = <TreeNode[]> data2.initialSelected;
              const initialSelectedIds = !isNullOrUndefined(initialSelected) ? initialSelected.map(d => d.id) : [];

              const notSelected = isNullOrUndefined(initialSelected) ? [] : initialSelected.filter(d => selectedTreeNodeIds.indexOf(d.id) === -1).map(d => {
                if (AppGlobal.byToken) {
                  return d.id;
                }
                const a = d.nodestructures === undefined ? (':' + d.id) : (d.nodestructures.filter(n => n.split(':')[0] === '' + treeNodeElement.modelId)[0]);
                return isNullOrUndefined(a) ? a : a.split(':')[1];
              }).filter(d => !!d);
              if (!isNullOrUndefined(treeNodeElement)) {
                /* Set the model ids */
                transfers.create.modelId = treeNodeElement.modelId;
                transfers.delete.modelId = treeNodeElement.modelId;
                transfers.update.modelId = treeNodeElement.modelId;
                /* Iterate over the selected tree nodes */
                const count0 = selectedTreeNodes.length;
                for (let i = 0; i < count0; i++) {
                  const selectedTreeNode = selectedTreeNodes[i];
                  if (initialSelectedIds.indexOf('' + selectedTreeNode.id) !== -1 && (isNullOrUndefined(data2.touched) || !data2.touched)) {
                    /* Node is known and was already connected */
                    continue;
                  }
                  if (treeNodeElement.connectedChildren !== undefined) {
                    key = key.split(':')[0];
                    const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
                    nodeDelta = nodeDelta.set(id, (nodeDelta.has(id) ? nodeDelta.get(id) : Map<string, any>()).set(key, delta));
                    continue;
                  }
                  if (forceCreate) {
                    key = key.split(':')[0];
                    const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
                      nodeDelta = nodeDelta.set(id, (nodeDelta.has(id) ? nodeDelta.get(id) : Map<string, any>()).set(key, delta));
                  }
                  if (selectedTreeNode.models.indexOf('' + treeNodeElement.modelId) !== -1) {
                    /* The node is already part of the model but was not connected so we connect them */
                    const b = selectedTreeNode.nodestructures.filter(n => n.split(':')[0] === '' + treeNodeElement.modelId)[0];
                    const id = isNullOrUndefined(b) ? b : b.split(':')[1];
                    if (!isNullOrUndefined(id)) {
                      key = key.split(':')[0];
                      if (key === 'dynamic-parents' || key === 'dynamic-reassign') {
                        if (isNullOrUndefined((_treeNode as TreeNode).unfilteredParents) || (_treeNode as TreeNode).unfilteredParents.filter(parent => parent.id === id).length === 0) {
                          (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: id, childId: _treeNode.id });
                        }
                      } else {
                        if (isNullOrUndefined((_treeNode as TreeNode).unfilteredChildren) || (_treeNode as TreeNode).unfilteredChildren.filter(child => child.id === id).length === 0) {
                          (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: _treeNode.id, childId: id });
                          if ((<TreeNode>_treeNode).nodeType === NODES_TYPE_GROUP) {
                            addResponsibleIds.push(selectedTreeNode.responsibleId);
                          }
                        }
                      }
                    }
                  }  else {
                    /* The node is not yet known in the target model so we group it over */
                    (<TreeNode[]> transfers.create.nodeStructures).push(<TreeNode> { id: selectedTreeNode.id, dataId: selectedTreeNode.dataId, name: selectedTreeNode.name });
                    /* Link the grouped node to selected node */
                    key = key.split(':')[0];
                    if (key === 'dynamic-parents' || key === 'dynamic-reassign') {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: selectedTreeNode.id, childId: _treeNode.id });
                    } else {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: _treeNode.id, childId: selectedTreeNode.id });

                    }

                    /* Check if a parent needs to be linked as well */
                    const parents = selectedTreeNode.parents.filter(parent => parent.checkboxValue === true && '' + parent.modelId !== '' + treeNodeElement.modelId);
                    const count2 = parents.length;
                    for (let i2 = 0; i2 < count2; i2++) {
                      (<TreeRelationship[]> transfers.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: parents[i2].id, childId: selectedTreeNode.id });
                    }
                  }
                }
                /* Get the relationships to delete */
                key = key.split(':')[0];
                if (key === 'dynamic-parents' || key === 'dynamic-reassign') {
                  (<TreeRelationship[]> transfers.delete.relationships) = (<TreeRelationship[]> transfers.delete.relationships).concat(this.coreService.getInstantRelationships({ filters: [{ by: 'childId', value: _treeNode.id }, { by: 'parentId', value: notSelected }] }));
                } else {
                  (<TreeRelationship[]> transfers.delete.relationships) = (<TreeRelationship[]> transfers.delete.relationships).concat(this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: _treeNode.id }, { by: 'childId', value: notSelected }] }));
                  const counta = notSelected.length;
                  if (counta > 0 ) {
                    removeResponsibleIds = initialSelected.filter(e => notSelected.indexOf(e.id) !== -1).map(e => e.responsibleId);
                  }
                  //  removeResponsibleIds.push(notSelected)
                }
                /* Modify the tree node in case of reassign */
                if (key === 'dynamic-reassign') {
                  let name = treeNode.name;
                  const oldName = initialSelected.length > 0 ? initialSelected[0].name : '';
                  const newName = selectedTreeNodes.length > 0 ? selectedTreeNodes[0].name : '';
                  if (oldName !== '') {
                    if (newName === '') {
                      name = List(treeNode.name.split(' - ')).pop().join(' - ');
                    } else {
                      name = treeNode.name.replace(oldName, newName);
                    }
                  }
                  if (name === treeNode.name) {
                    name = List(treeNode.name.split(' - ')).pop().push(newName).join(' - ');
                  }
                  (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNodeElement.dataId, data: Map().set('name', name) });
                }
              }
              break;
            case 'dynamic-gantt':
              break;
            case 'dynamic-assign':
              let assign = this.coreUtilities.mapFormResult(formResult).delta.get('dynamic-assign');
              if (!isNullOrUndefined(transfers) && assign !== '' && !isNullOrUndefined(assign)) {
                if (!(assign instanceof Map)) {
                  assign = Map<string, CoreMultiTransfer>(assign);
                }
                assign.forEach(assignTransfer => {
                  transfers = this.coreUtilities.concatTransfers(transfers, assignTransfer);
                });
              }
              break;
            case 'dynamic-transfer':
              if (delta !== '') {
                transfers = this.coreUtilities.mergeTransfers(transfers, delta);
              }
              break;
            case 'responsibleId':
              if (isNull((<TreeNode> entryTreeNode).responsibleId) && delta === 0) {
                break;
              }
            case 'nodeType':
              let additional: TreeNode;
              if (formResult.delta.has('nodeType-additional-detail:select')) {
                additional = AppGlobal.treeNodes.get(formResult.delta.get('nodeType-additional-detail:select'));
              } else if (formResult.delta.has('nodeType-additional:select')) {
                additional = AppGlobal.treeNodes.get(formResult.delta.get('nodeType-additional:select'));
              }
              if (additional !== undefined) {
                delta = additional.nodeType;
                const byNodes = this.coreUtilities.getChildren(additional.children).filter(child => child.nodeType === NODES_TYPE_BY);
                transfers.create = this.coreUtilities.addRestrictionsToRequest((treeNode as TreeNode), byNodes, transfers.create.modelId, undefined, transfers.create, false);
              }
            default:
              /* Switch by control type */
              switch (entry.controlType) {
                case 'reminder':
                  /* Find the reminder nodes */
                  const reminderNodes = (treeNode as TreeNode).children.filter(child => child.nodeType === NODES_TYPE_REMINDER);
                  /* Get the new moment */
                  const mom = moment(delta.value).format('YYYY-MM-DDTHH:mm:ss');
                  /* Iterate over children */
                  const countRem = reminderNodes.length;
                  for (let i = 0; i < countRem; i++) {
                    const reminderNode = reminderNodes[i];
                    nodeDelta = nodeDelta.set(reminderNode.dataId, (nodeDelta.has(reminderNode.dataId) ? nodeDelta.get(reminderNode.dataId) : Map<string, any>()).set(key.split(':')[0], mom));
                  }
                  break;
                default:
                  /* Set the value */
                  key = key.split(':')[0];
                  if (key === 'level' || key === 'active' || key === 'positionX') {
                    nodeStructureDelta = nodeStructureDelta.set(entryTreeNode.id, (nodeStructureDelta.has(entryTreeNode.id) ? nodeStructureDelta.get(entryTreeNode.id) : Map<string, any>()).set(key, delta));
                  } else {
                    const id = isNullOrUndefined((<TreeNode> entryTreeNode).dataId) ? entryTreeNode.id : (<TreeNode> entryTreeNode).dataId;
                    nodeDelta = nodeDelta.set(id, (nodeDelta.has(id) ? nodeDelta.get(id) : Map<string, any>()).set(key, delta));
                  }
              }
          }
        }
      }
    });
    /* Generate node payload from map */
    if (nodeDelta.size > 0) {
      nodeDelta.forEach((delta, id) => {
        if (coefficientCreateTreeNode !== undefined) {
          delta.forEach((v, k) => {
            coefficientCreateTreeNode[k] = v;
          });
        } else  if (create) {
          if (forceCreate) {
            (<IPayload[]> transfers.create.nodes).push(Object.assign(<TreeNode> { id: treeNode.id, nodeType: (<TreeNode> treeNode).nodeType }, delta.toJS()));
          } else {
            (<IPayload[]> transfers.create.nodes).push(Object.assign(<TreeNode> { id: treeNode.id, nodeType: (<TreeNode> treeNode).nodeType }, delta.toJS()));
            if ((<TreeNode> treeNode).nodeType === NODES_TYPE_GROUP && updateGroups) {
              (<CoreGroup[]>transfers.create.groups).push(<CoreGroup>{
                id: treeNode.id,
                name: delta.get('name'),
                humanResourcesGo: addResponsibleIds
              });
            }
          }
        } else {
          const filteredDelta = delta.filter((v, key) => key !== '' && key !== null && key !== undefined);
          if (filteredDelta.size > 0) {
            (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: id, data: filteredDelta });
            if ((<TreeNode> treeNode).nodeType === NODES_TYPE_GROUP && (<TreeNode>treeNode).groupId !== undefined && updateGroups) {
              let groupDelta = Map(delta);
              if (addResponsibleIds.length > 0) {
                groupDelta = groupDelta.set('addHR', addResponsibleIds);
              }
              if (removeResponsibleIds.length > 0) {
                groupDelta = groupDelta.set('removeHR', removeResponsibleIds);
              }
              if (groupDelta.size > 0) {
                (<IPayload[]>transfers.update.groups).push(<IPayload>{id: '' + (<TreeNode>treeNode).groupId, data: {attributes: delta, relations: groupDelta}});
              }
            }
          }
        }
      });
    } else if ((<TreeNode> treeNode).nodeType === NODES_TYPE_GROUP && (<TreeNode>treeNode).groupId !== undefined && updateGroups) {
      let delta = Map();
      if (addResponsibleIds.length > 0) {
        delta = delta.set('addHR', addResponsibleIds);
      }
      if (removeResponsibleIds.length > 0) {
        delta = delta.set('removeHR', removeResponsibleIds);
      }
      if (delta.size > 0) {
        (<IPayload[]>transfers.update.groups).push(<IPayload>{id: '' + (<TreeNode>treeNode).groupId, data: {relations: delta}});
      }
    }

    /* Fix on coefficient */
    if (coefficientCreateTreeNode !== undefined) {
      (transfers.create.relationships as TreeRelationship[]) = (transfers.create.relationships as TreeRelationship[]).map(relationship => {
        if (relationship.parentId !== coefficientCreateTreeNode.id && relationship.childId !== coefficientCreateTreeNode.id) {
          if (relationship.parentId === treeNode.id) {
            relationship.parentId = coefficientCreateTreeNode.id;
          }
          if (relationship.childId === treeNode.id) {
            relationship.childId = coefficientCreateTreeNode.id;
          }
        }
        return relationship;
      });
    }

    /* Generate node structure payload from map */
    if (nodeStructureDelta.size > 0) {
      nodeStructureDelta.forEach((delta, id) => {
        if (create) {
          (<IPayload[]> transfers.create.nodeStructures).push(Object.assign(<TreeNode> { id: treeNode.id, nodeType: (<TreeNode> treeNode).nodeType }, delta.toJS()));
        } else {
          (<IPayload[]> transfers.update.nodeStructures).push(<IPayload> { id: id, data: delta });
        }
      });
    }

    if (activityDelta.size > 0) {
      activityDelta.forEach((delta, id) => {
        if (isNullOrUndefined(id)) {
          id = !isNullOrUndefined((<TreeActivity> treeNode).nodeData) ? (<TreeActivity> treeNode).nodeData : (!isNullOrUndefined((<TreeActivity> treeNode).instance) ? (<TreeActivity> treeNode).instance : undefined);
          if (!delta.has('name')) {
            delta = delta.set('name', treeNode.name);
          }
          if (!delta.has('nodebucket')) {
            delta = delta.set('nodebucket', true);
          }
          if (!isNullOrUndefined(id)) {
            (<IPayload[]> transfers.create.activities).push(<IPayload> { id: id, data: delta });
          }
        } else {
          (<IPayload[]> transfers.update.activities).push(<IPayload> { id: id, data: delta });
        }
      });
    }

    /* Create node buckets if needed */
    if (bucketDelta.size > 0) {
      bucketDelta.forEach((d1: Map<string, Map<string, any>>, treeNodeId: string) => {
        d1.forEach((d2: Map<string, any>, bucketId: string) => {
          const bucket = buckets.get(bucketId);
          if (bucket.phantom) {
            /* Create the bucket */
            d2.forEach((value, key) => {
              bucket[key] = value;
            });
            (<IPayload[]> transfers.create.activities).push(<IPayload> { id: treeNodeId, data: PayloadService.toPayload(this.coreTransformer.treeActivityToActivity(bucket)) });
          } else {
            let bucketDeltaMap = Map<string, any>();
            d2.forEach((value, key) => {
              if (isNaN(value) || value === 'DATASHEET.GENERAL.DIFFERS') {
              } else {
                bucketDeltaMap = bucketDeltaMap.set(key, value);
              }
            });
            /* Update the bucket */
            if (bucketDeltaMap.size > 0) {
              (<IPayload[]> transfers.update.activities).push(<IPayload> { id: bucket.id, data: bucketDeltaMap });
            }
          }
        });
      });
    }
    return transfers;
  }

  public getCoreTransferFromForm(formResult: FormResult, form: FormInterface, treeRelationship: TreeRelationship): CoreTransfer {
    formResult = this.coreUtilities.mapFormResult(formResult);
    const transfer = { nodes: [], relationships: [], activities: [] };

    /* Generate node payload from map */
    if (formResult.delta.size > 0) {
      (<IPayload[]> transfer.relationships).push(<IPayload> { id: treeRelationship.id, data: formResult.delta });
    }

    return transfer;
  }

  public getCoreTransferFromField(formField: TreeNode, value: any, treeNode: TreeNode): CoreMultiTransfer {
    const transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [] },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [] }
    };

    if (formField.formBucket) {
      const bucket = this.getBucket(treeNode);
      /* Set the value */
      bucket[formField.formFieldId] = value;
      if (bucket.phantom) {
        /* Create the bucket */
        (<IPayload[]> transfers.create.activities).push(<IPayload> { id: treeNode.dataId, data: PayloadService.toPayload(this.coreTransformer.treeActivityToActivity(bucket)) });
      } else {
        const data = {};
        data[formField.formFieldId] = value;
        /* Update the bucket */
        (<IPayload[]> transfers.update.activities).push(<IPayload> { id: bucket.id, data: data });
      }
    } else {
      (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: Map<string, any>().set(formField.formFieldId, value) });
    }

    return transfers;
  }

  protected createHumanResourceByForm(formResult: FormResult, coreHumanResource: CoreHumanResource, fieldNodes: TreeNode[], callback: Function) {
    /* Check if a human resource is already set */
    if (isNullOrUndefined(coreHumanResource.id) || isNullOrUndefined(coreHumanResource.responsibleId) || coreHumanResource.responsibleId === 0) {
      /* Check if a similar hr is already there */
      const humanResources = <CoreHumanResource[]> this.coreService.getInstantHumanResources();
      let findings = OrderedMap<string, CoreHumanResource>();
      const count = humanResources.length;
      for (let i = 0; i < count; i++) {
        const humanResource = humanResources[i];
        if (humanResource.instanceId === this.coreService.getBusinessAreaInstant().relationships.instance &&
          (humanResource.first_name.indexOf(coreHumanResource.first_name) !== -1 ||
            humanResource.last_name.indexOf(coreHumanResource.last_name) !== -1 ||
            (coreHumanResource.email !== '' && humanResource.email !== undefined && humanResource.email !== null && humanResource.email.indexOf(coreHumanResource.email) !== -1))) {
          const id = humanResource.first_name + ';' + humanResource.last_name + ';' + humanResource.email;
          if (!findings.has(id)) {
            findings = findings.set(id, humanResource);
          }
        }
      }
      if (!isNullOrUndefined(fieldNodes) && findings.size > 0) {
        /* Now get the form modal */
        this.coreService.modal('form-table-modal', (modal: FormTableModalComponent) => {
          modal.title = 'Potential duplicates - ' + coreHumanResource.name;
          /* Register the listeners */
          this.subscriptionService.add('select', modal.select.subscribe(humanResourceId => {
            modal.close();
            callback(this.coreService.getInstantHumanResources().filter(h => '' + h.id === '' + humanResourceId)[0]);
          }));
          this.subscriptionService.add('cancel', modal.cancel.subscribe(() => {
            callback(undefined);
          }));
          this.subscriptionService.add('ignore', modal.ignore.subscribe(() => {
            coreHumanResource.id = undefined;
            modal.close();
            callback(this.coreTransformer.coreHumanResourceToHumanResource(coreHumanResource).remove('name'));
          }));
          /* Show modal */
          modal.show(<any> findings.toArray(), fieldNodes.filter(fieldNode => fieldNode.formFieldId !== 'dynamic-as-user'));
        });
      } else {
        callback(this.coreTransformer.coreHumanResourceToHumanResource(coreHumanResource).remove('name').set('id', undefined));
      }
    }
  }

  public getCoreTransferHumanResource(formResult: FormResult, coreHumanResource: CoreHumanResource, modelId: string, resolve: Function, fieldNodes?: TreeNode[], treeNode?: TreeNode) {
    /* Set transfer */
    let transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [], modelId: modelId },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [], humanResources: [] }
    } as CoreMultiTransfer;

    /* Map form result to core human resource */
    if (coreHumanResource instanceof HumanResource) {
      coreHumanResource = this.coreTransformer.humanResourceToCoreHumanResource(coreHumanResource);
    }
    formResult.delta.forEach((value, key) => {
      coreHumanResource[key] = value;
    });

    /* Check if the HR should be created as user */
    const dynamicAsUser = formResult.delta.get('dynamic-as-user');

    /* Clear the dynamic */
    formResult.delta = formResult.delta.remove('dynamic-as-user');
    formResult.delta = formResult.delta.remove('dynamic-has-access');

    if (!isNullOrUndefined(dynamicAsUser) && dynamicAsUser !== false && dynamicAsUser !== '') {
      if (treeNode !== undefined) {
        /* Update */
        /* Get the deltas */
        let nodeDelta = Map<string, any>();
        const transformed = this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource, treeNode.id, transfers);
        transfers = transformed.transfer;
        const keys = Object.keys(transformed.treeNode);
        const count = keys.length;
        for (let i = 0; i < count; i++) {
          const key = keys[i];
          const value = transformed.treeNode[key];
          if (!isNullOrUndefined(treeNode[key]) && treeNode[key] !== value && key !== 'id' && key !== 'responsibleId') {
            nodeDelta = nodeDelta.set(key, value);
          }
        }
        /* Check if a human resource was selected before */
        if (isNullOrUndefined(treeNode.responsibleId) || treeNode.responsibleId === 0) {
          this.createHumanResourceByForm(formResult, coreHumanResource, fieldNodes, (humanResource: HumanResource) => {
            if (humanResource !== undefined) {
              if (humanResource.id === undefined) {
                humanResource = humanResource.set('id', UUID.UUID()) as HumanResource;
                (<HumanResource[]> transfers.create.humanResources).push(humanResource);
              }
              transfers.waitForCreate = true;
              (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: Map().set('responsibleId', humanResource.id) });
            }
            resolve(transfers);
          });
        } else {
          /* Now build the payloads from deltas */
          if (nodeDelta.size > 0) {
            (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: nodeDelta });
          }
          (<IPayload[]> transfers.update.humanResources).push(<IPayload> { id: '' + coreHumanResource.id, data: formResult.delta });
          resolve(transfers);
        }
      } else {
        /* Generate node with related human resource */
        const id = UUID.UUID();
        /* Create a human resource as well */
        this.createHumanResourceByForm(formResult, coreHumanResource, fieldNodes, (humanResource: HumanResource) => {
          if (humanResource !== undefined) {
            const responsibleId = humanResource.id;
            if (responsibleId === undefined) {
              humanResource = humanResource.set('id', id) as HumanResource;
              (<HumanResource[]> transfers.create.humanResources).push(humanResource);
            } else {
              coreHumanResource.first_name = humanResource.first_name;
              coreHumanResource.last_name = humanResource.last_name;
              coreHumanResource.name = humanResource.name;
            }
            const transformed = this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource, id, transfers);
            transformed.treeNode.responsibleId = responsibleId;
            transfers = transformed.transfer;
            (<TreeNode[]> transfers.create.nodes).push(transformed.treeNode);
          }
          resolve(transfers);
        });
      }
    } else {
      if (treeNode !== undefined) {
        /* Update */
        /* Get the deltas */
        let nodeDelta = Map<string, any>();
        const transformed = this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource, treeNode.id, transfers);
        transfers = transformed.transfer;
        const keys = Object.keys(transformed.treeNode);
        const count = keys.length;
        for (let i = 0; i < count; i++) {
          const key = keys[i];
          const value = transformed.treeNode[key];
          if (!isUndefined(treeNode[key]) && treeNode[key] !== value && key !== 'id' && key !== 'responsibleId') {
            nodeDelta = nodeDelta.set(key, value);
          }
        }
        /* Check if a human resource was selected before */
        if (!isNullOrUndefined(treeNode.responsibleId)) {
          nodeDelta = nodeDelta.set('responsibleId', null);
        }
        /* Now build the payloads from deltas */
        if (nodeDelta.size > 0) {
          (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: nodeDelta });
        }
        if (dynamicAsUser) {
          (<IPayload[]> transfers.update.humanResources).push(<IPayload> { id: '' + coreHumanResource.id, data: formResult.delta });
        }
      } else {
        /* Create the HR as node */
        const transformed = this.coreTransformer.coreHumanResourceToTreeNode(coreHumanResource, UUID.UUID(), transfers);
        transfers = transformed.transfer;
        (<TreeNode[]> transfers.create.nodes).push(transformed.treeNode);
      }
      resolve(transfers);
    }
  }

  public getDynamicTransfer(treeNode: TreeNode, key: string, transfer: CoreMultiTransfer) {
    /* Dynamic parents */
    if (treeNode[key] !== undefined) {
      const data = treeNode[key];

      const treeNodeElement = <TreeNode> data.element;
      const selectedTreeNodes = <TreeNode[]> data.selected;
      const selectedTreeNodeIds = !isNullOrUndefined(selectedTreeNodes) ? selectedTreeNodes.map(d => d.id) : [];
      const initialSelected = <TreeNode[]> data.initialSelected;
      const initialSelectedIds = !isNullOrUndefined(initialSelected) ? initialSelected.map(d => d.id) : [];

      const notSelected = isNullOrUndefined(initialSelected) ? [] : initialSelected.filter(d => selectedTreeNodeIds.indexOf(d.id) === -1).map(d => {
        if (AppGlobal.byToken) {
          return d.id;
        }
        const a = d.nodestructures.filter(n => n.split(':')[0] === '' + treeNodeElement.modelId)[0];
        return isNullOrUndefined(a) ? a : a.split(':')[1];
      }).filter(d => !!d);

      /* Iterate over the selected tree nodes */
      const count0 = selectedTreeNodes.length;
      for (let i = 0; i < count0; i++) {
        const selectedTreeNode = selectedTreeNodes[i];
        if (initialSelectedIds.indexOf('' + selectedTreeNode.id) !== -1 && (isNullOrUndefined(data.touched) || !data.touched)) {
          /* Node is known and was already connected */
          continue;
        }
        if (selectedTreeNode.models.indexOf('' + treeNodeElement.modelId) !== -1) {
          /* The node is already part of the model but was not connected so we connect them */
          const b = selectedTreeNode.nodestructures.filter(n => n.split(':')[0] === '' + treeNodeElement.modelId)[0];
          const nodeStructureId = isNullOrUndefined(b) ? b : b.split(':')[1];
          if (!isNullOrUndefined(nodeStructureId)) {
            if (key === 'dynamic-parents') {
              if (isNullOrUndefined(treeNode.unfilteredParents) || treeNode.unfilteredParents.filter(parent => parent.id === selectedTreeNode.id).length === 0) {
                (<TreeRelationship[]> transfer.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: selectedTreeNode.id, childId: '' + treeNode.id });
              }
            } else {
              if (isNullOrUndefined(treeNode.unfilteredChildren) || treeNode.unfilteredChildren.filter(child => child.id === selectedTreeNode.id).length === 0) {
                (<TreeRelationship[]> transfer.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: '' + treeNode.id, childId: selectedTreeNode.id });
              }
            }
          }
        }  else {
          /* The node is not yet known in the target model, so we group it over */
          (<TreeNode[]> transfer.create.nodeStructures).push(<TreeNode> { id: selectedTreeNode.id, dataId: selectedTreeNode.dataId, name: selectedTreeNode.name });
          /* Link the grouped node to selected node */
          if (key === 'dynamic-parents') {
            (<TreeRelationship[]> transfer.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: selectedTreeNode.id, childId: '' + treeNode.id });
          } else {
            (<TreeRelationship[]> transfer.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: '' + treeNode.id, childId: selectedTreeNode.id });

          }
          /* Check if a parent needs to be linked as well */
          const parents = selectedTreeNode.parents.filter(parent => parent.checkboxValue === true && '' + parent.modelId !== '' + treeNodeElement.modelId);
          const count2 = parents.length;
          for (let i2 = 0; i2 < count2; i2++) {
            (<TreeRelationship[]> transfer.create.relationships).push(<TreeRelationship> { id: UUID.UUID(), parentId: parents[i2].id, childId: selectedTreeNode.id });
          }
        }
      }
      /* Get the relationships to delete */
      if (key === 'dynamic-parents') {
        (<TreeRelationship[]> transfer.delete.relationships) = (<TreeRelationship[]> transfer.delete.relationships).concat(this.coreService.getInstantRelationships({ filters: [{ by: 'childId', value: '' + treeNode.id }, { by: 'parentId', value: notSelected }] }));
      } else {
        (<TreeRelationship[]> transfer.delete.relationships) = (<TreeRelationship[]> transfer.delete.relationships).concat(this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: '' + treeNode.id }, { by: 'childId', value: notSelected }] }));
      }
    }

    return transfer;
  }

  public getCoreTransferGroup(formResult: FormResult, coreGroup: CoreGroup, modelId: string, treeNode?: TreeNode): CoreMultiTransfer {
    const transfers = {
      create: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [], modelId: modelId },
      update: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [] },
      delete: <CoreTransfer> { nodes: [], relationships: [], activities: [], groups: [] }
    };

    if (coreGroup instanceof Group) {
      coreGroup = this.coreTransformer.groupToCoreGroup(coreGroup);
    }
    formResult.delta.forEach((value, key) => coreGroup[key] = value);

    if (isNullOrUndefined(coreGroup.id)) {
      const id = UUID.UUID();
      (<Group[]> transfers.create.groups).push(<Group> this.coreTransformer.coreGroupToGroup(coreGroup).set('id', id));
      (<NodeCreate[]> transfers.create.nodes).push(<NodeCreate> this.coreTransformer.treeNodeToNodeCreate(<any> this.coreTransformer.coreGroupToTreeNode(coreGroup)).set('id', id));
    } else {
      if (!isNullOrUndefined(treeNode)) {
        /* Get the deltas */
        let nodeDelta = Map<string, any>();
        Map(this.coreTransformer.coreGroupToTreeNode(coreGroup)).forEach((value: any, key: string) => {
          if (!isNullOrUndefined(treeNode[key]) && treeNode[key] !== value && key !== 'id' && key !== 'groupId') {
            nodeDelta = nodeDelta.set(key, value);
          }
        });
        /* Now build the payloads from deltas */
        (<IPayload[]> transfers.update.nodes).push(<IPayload> { id: treeNode.dataId, data: nodeDelta });
        (<IPayload[]> transfers.update.groups).push(<IPayload> { id: '' + coreGroup.id, data: formResult.delta });
      }
    }

    return transfers;
  }

  public getReadableValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, fields?: any, tableConfigurationOptions?: TableConfigurationOptions, nodeIds?: string[], messages?: EventEmitter<CoreWidgetMessage[]>, nextInternalSublevel?) {
    /* Get the value */
    let value = this.getValue(fieldNode, treeNode, tableConfigurationOptions);
    /* Set default value if value is undefined */
    if (value === undefined && !isNullOrUndefined(fieldNode.form_default_value)) {
      value = fieldNode.form_default_value;
    }
    /* If there is a conversion assigned */
    if (!isNullOrUndefined(fieldNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(fieldNode.fieldConversion, value);
    }
    /* If id is not known */
    if (isNullOrUndefined(value) && fieldNode.formFieldControlType !== 'questionnaire' && fieldNode.formFieldControlType !== 'calculated' && fieldNode.formFieldControlType !== 'sublevel' &&
      fieldNode.formFieldControlType !== 'assign' && fieldNode.formFieldControlType !== 'violatedParagraph' && fieldNode.formFieldControlType !== 'node-select' &&
      fieldNode.formFieldControlType !== 'comments' && fieldNode.formFieldControlType !== 'count' && fieldNode.formFieldControlType !== 'action-buttons' && fieldNode.formFieldControlType !== 'colorlabelprovider') {
      return '';
    }
    /* Switch by control type */
    switch (fieldNode.formFieldControlType) {
      case 'questionnaire': {
        const obj = (fieldNode.formFieldId === 'description2') ? (<TreeNode>treeNode).jsonObj2 : (<TreeNode>treeNode).jsonObj1;
        if (isNullOrUndefined(obj)) {
          return '';
        }
        const vals = fieldNode.children.map(e => ({label: e.name, key: e.crossReference}));
        const count = vals.length;
        let result = '';
        // return "position_x: 1234 <br/> name: Hannes"
        for (let i = 0; i < count; i++) {
          const val = vals[i];
          if (isNullOrUndefined(obj[val.key])) {
            continue;
          } else {
            result = val.label + ': ' + obj[val.key] + '<br/>';
          }
        }
        return result;
      }
        break;
      case 'dropdown':
        switch (fieldNode.formFieldId) {
          case 'nodeType':
            const count = fieldNode.children.length;
            if (count > 0) {
              for (let i = 0; i < count; i++) {
                const child = fieldNode.children[i];
                if (child.nodeType === value) {
                  return child.name;
                }
              }
            }
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            return nodeTypes.length > 0 ? (nodeTypes[0].label === '' ? '' : this.translateService.instant(nodeTypes[0].label)) : '';
          default:
            switch (fieldNode.formFieldDropdownValue) {
              case 'humanresources':
                return this.humanResourcesMap.has('' + treeNode.responsibleId) ? this.humanResourcesMap.get('' + treeNode.responsibleId).name : '';
              case 'humanresourceimages':
                let src = '';
                const humanResource = this.humanResourcesMap.get('' + treeNode.responsibleId);
                if (!isNullOrUndefined(humanResource) && !isNullOrUndefined(humanResource.image) && humanResource.image !== '') {
                  src = humanResource.image;
                } else {
                  const id = !isNullOrUndefined(humanResource) ? humanResource.foreign_id : 0;
                  if (!isNullOrUndefined(id) && id !== 0) {
                    src = AppGlobal.imageUrl + id;
                  }
                }
                return isNullOrUndefined(src) || src === '' ? '' : '<img class="humanresource" src="' + src + '" />';
              case 'shape':
                const shapeValue = treeNode[fieldNode.formFieldId];
                let shape = 'circle';
                let color = !isNullOrUndefined(fieldNode.colors) ? fieldNode.colors[0] : '#000000';
                let style = 'background: ' + color;
                const shapeNode = fieldNode.children.filter(child => '' + child.formId === '' + shapeValue)[0];
                if (!isNullOrUndefined(shapeNode)) {
                  /* Set color */
                  if (shapeNode.color !== '') {
                    color = shapeNode.color;
                  }
                  /* Set shape */
                  if (!isNullOrUndefined(shapeNode.formFieldShape) && shapeNode.formFieldShape !== '') {
                    shape = shapeNode.formFieldShape;
                  }
                  if (shape === 'triangle') {
                    style = 'border-color: transparent transparent ' + color + ' transparent;';
                  } else {
                    style = 'background-color: ' + color + ';';
                  }
                }
                return isNullOrUndefined(shapeNode) ? '' : '<div class="' + shape + '" style="' + style + '"></div>';
              case 'statuscolor':
                const status = isNullOrUndefined(CoreStatus['' + value]) ? CoreStatus['0'] : CoreStatus['' + value];
                return '<div class="circle" style="background: ' + status.color + '"></div>';
              case 'status':
                const statusText = isNullOrUndefined(CoreStatus['' + value]) ? CoreStatus['0'] : CoreStatus['' + value];
                return this.translateService.instant(statusText.text);
              case 'level':
                const levelText = isNullOrUndefined(CoreLevel['' + value]) ? CoreLevel['0'] : CoreLevel['' + value];
                return this.translateService.instant(levelText.text);
              case 'markets':
                const mv = treeNode[fieldNode.formFieldId];
                if (!this.marketsMap.has(mv)) {
                  return '';
                }
                const mvNode = this.marketsMap.get(mv);
                return mvNode.name + ' <span class="float-right fflag fflag-' + mvNode.reference.toUpperCase() + '"></span>';
              case 'overbooked':
                if ((<TreeNode> treeNode).nodeType !== NODES_TYPE_GROUP || isNaN((<TreeNode> treeNode).budgetCalculated)) {
                  return '';
                }
                let val = '#999999';
                const colorNode = fieldNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION && parseFloat(child.formId) < (<TreeNode> treeNode).budgetCalculated).sort((a, b) => parseFloat(b.formId) - parseFloat(a.formId))[0];
                if (!isNullOrUndefined(colorNode)) {
                  val = colorNode.color;
                }
                return '<div class="circle" style="background: ' + val + '"></div>';
              default:
                if ((value === '' || isNullOrUndefined(value)) && (fieldNode.formFieldType === '' || fieldNode.formFieldType === 'number')) {
                  value = '0';
                }
                const option = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION && '' + child.formId === '' + value)[0];
                return !isNullOrUndefined(option) ? option.name : '';
            }
        }
      case 'calculated':
        return this.getCalculatedValue(fieldNode, treeNode, fields);
      case 'sublevel':
        const subLevel = !isNullOrUndefined((<TreeNode> treeNode).internalSubLevel) ? (<TreeNode> treeNode).internalSubLevel : 0;
        const margin = subLevel * 10;
        if ((<TreeNode> treeNode).collapsed) {
          return '<button rel="node:' + treeNode.id + ':expand" style="background-color:transparent; margin: auto 0 auto ' + margin + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Expand"><i style="font-size:16px" class="fa fa-caret-right"></i></button>';
        } else if (!isNullOrUndefined(nextInternalSublevel)) {
          if ((<TreeNode> treeNode).internalSubLevel < nextInternalSublevel) {
            return '<button rel="node:' + treeNode.id +
              ':collapse" style="background-color:transparent; margin: auto 0 auto ' + margin + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Collapse" ><i style="font-size:16px" class="fa fa-caret-down"></i></button>';
            }
        } else if ((<TreeNode> treeNode).children.filter(child => nodeIds === undefined || nodeIds.indexOf(child.id) !== -1).length > 0) {
          return '<button rel="node:' + treeNode.id +
            ':collapse" style="background-color:transparent; margin: auto 0 auto ' + margin + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Collapse" ><i style="font-size:16px" class="fa fa-caret-down"></i></button>';
        }
        return '<button style="background-color:transparent; margin: auto 0 auto ' + margin + 'px;" class="button button-icon button-no-hover"><i style="font-size: 6px; color:#999999" class="fa fa-circle"></i></button>';
      case 'sublevelWithName':
        if (isNullOrUndefined((<TreeNode> treeNode).subLevel)) {
          return '';
        }
        const marginWN = (<TreeNode> treeNode).subLevel * 10;
        const name = isNullOrUndefined((<TreeNode> treeNode).subLevelName) ? treeNode.name : (<TreeNode> treeNode).subLevelName;
        if ((<TreeNode> treeNode).children.length > 0) {
          if ((<TreeNode> treeNode).collapsed) {
            return '<button rel="node:' + treeNode.id + ':expand" style="margin-left: ' + marginWN + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Expand" ><i class="fa fa-angle-down"></i></button>' + name;
          } else {
            return '<button rel="node:' + treeNode.id + ':collapse" style="margin-left: ' + marginWN + 'px;" class="button button-icon" data-toggle="tooltip" data-placement="bottom" title="Collapse" ><i class="fa fa-angle-up"></i></button>' + name;
          }
        }
        return '<button style="margin-left: ' + marginWN + 'px;" class="button button-icon button-no-hover"></button>' + name;
      case 'checkbox':
        return '<i style="pointer-events: none;" class="fa ' + (value ? 'fa-check-square' : 'fa-square') + '"></i>';
      case 'date-quarter':
        return moment(value).subtract(fieldNode.budget, 'months').format('[Q]Q - [Y]YYYY');
      case 'cw':
        const datum = new Datum(this.getValue(fieldNode, treeNode));
        return fieldNode.form_default_value !== null && fieldNode.form_default_value !== '' ? moment(datum.date).format('' + fieldNode.form_default_value) : datum.week;
      case 'due-date':
      case 'daterange':
      case 'date':
        let format = 'DD.MM.YYYY';
        if (!isNullOrUndefined(fieldNode.form_default_value) && fieldNode.form_default_value !== '') {
          format = '' + fieldNode.form_default_value;
        }
        if (isNumber(value)) {
          value = value * 1000;
        }
        return moment(value).format(format);
      case 'lastEditedBy':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const lastAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId)[0] : undefined;
            if (!isNullOrUndefined(lastAudit) && lastAudit.type === 'humanresources') {
              this.coreService.getHumanResources().subscribe(humanResource => {
                const user = humanResource.filter(e => '' + e.id === '' + lastAudit.userId)[0];
                resolve(!isNullOrUndefined(user) ? user.name : '');
              });
            } else {
              resolve('Superuser');
            }
          });
        });
      case 'lastEditedWhat':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const lastAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId)[0] : undefined;
              const before: any = Object.values(lastAudit.before);
              // const after: any =  Object.values(lastAudit.after)[0];
              const count = before.length;
              const result = {before: [], after: [], attribute: []};
              for (let index = 0; index < count; index++) {
                const element = before[index];
                const after: any = Object.values(lastAudit.after)[index];
                const key = Object.keys(lastAudit.after)[index];
                result.attribute.push(Object.keys(lastAudit.after)[index]);

                if (isString(element)) {
                  result.before.push(element.replace(/<p>|<\/p>/gm, ''));
                  result.after.push((isNullOrUndefined(after)) ? 'undefined' : after.replace(/<p>|<\/p>/gm, ''));
                  // resolve({before: before.replaceAll(/<p>|<\/p>/gm, ''), after: after.replaceAll(/<p>|<\/p>/gm, ''), attribute: Object.keys(lastAudit.after)[0]});
                } else {
                  // resolve({before: Object.values(lastAudit.before)[0], after: Object.values(lastAudit.after)[0], attribute: Object.keys(lastAudit.after)[0]});
                  result.before.push(isNullOrUndefined(element) ? 'undefined' : element);
                  result.after.push(isNullOrUndefined(after) ? 'undefined' : after);
                }
              }
              resolve(result);

          });
        });
      case 'lastEditedWhen':
      return new Promise(resolve => {
        this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
          const lastAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId)[0] : undefined;
          resolve(lastAudit.readableUpdatedAt);
        });
      });

      case 'createdAt':
      return new Promise(resolve => {
        this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
          const audits = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId) : undefined;
          resolve(audits[audits.length - 1].readableUpdatedAt);
          // resolve(lastAudit.readableUpdatedAt);
        });
      });
      case 'createdBy':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const audits = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId) : undefined;
            const firstAudit = audits[audits.length - 1];
            if (!isNullOrUndefined(firstAudit) && firstAudit.type === 'humanresources') {
              this.coreService.getHumanResources().subscribe(humanResource => {
                const user = humanResource.filter(e => '' + e.id === '' + firstAudit.userId)[0];
                resolve(!isNullOrUndefined(user) ? user.name : '');
              });
            } else {
              resolve('Superuser');
            }
          });
        });
      case 'validatedBy':
        return new Promise(resolve => {
          this.coreService.getNodeDataAuditsByNode(<TreeNode> treeNode).then(auditMap => {
            const validatedAudit = auditMap.has((<TreeNode> treeNode).dataId) ? auditMap.get((<TreeNode> treeNode).dataId).filter(audit => '' + audit.after['commercialStatus'] === '1')[0] : undefined;
            if (!isNullOrUndefined(validatedAudit) && !isNullOrUndefined(validatedAudit.userId)) {
              this.coreService.getHumanResources({ filters: [{ by: 'id', value: validatedAudit.userId }] }).take(1).subscribe(humanResources => {
                resolve(humanResources.length > 0 ? humanResources[0].name : '');
              });
            }
          });
        });
      case 'node-select':
        return this.getConnectedNodes(fieldNode, treeNode as TreeNode).map(c => c.name).join(fieldNode.formId === '' ? ', ' : fieldNode.formId);
      case 'select':
        const active = fieldNode.formId + '-' + treeNode.id === this.appGlobal.tableButtonClickedEvent ? ' button-active' : '';
        return '<button rel="node:' + treeNode.id + ':click:' + fieldNode.formId + '" data-key="' + fieldNode.formId + '" class="button button-selectable' + active + '">' + fieldNode.name + '</button>';
      case 'file-status':
        return '<i class="fa ' + (treeNode.status !== 200 ? 'fa-spinner fa-spin' : 'fa-check') + '"></i>';
      case 'priority-score':
        return !isNullOrUndefined(this.eisenhowerService) ? this.eisenhowerService.getPriorityScore(<TreeNode> treeNode) : 0;
      case 'assign':
        let storedAssigned: boolean;
        if (AppGlobal.assignSelected[fieldNode.originalId] !== undefined && AppGlobal.assignSelected[fieldNode.originalId][fieldNode.id] !== undefined && AppGlobal.assignSelected[fieldNode.originalId][fieldNode.id].has(treeNode.id)) {
          storedAssigned = AppGlobal.assignSelected[fieldNode.originalId][fieldNode.id].get(treeNode.id);
        }
         return '<i class="fa ' + ((storedAssigned !== undefined ? storedAssigned : ((treeNode as TreeNode).checkboxValue || fieldNode.selected || this.coreService.isElementAssigned(fieldNode.formFieldId, <TreeNode> treeNode, tableConfigurationOptions.connectNodeType))) ? 'fa-check-square' : 'fa-square') + '"></i>';
      case 'action-buttons':
        return !isNullOrUndefined(this.actionButtonsService) ? '<div class="action-buttons">' + this.actionButtonsService.setDataNode(<TreeNode> treeNode).setButtonNodes(fieldNode.children).setMessageEmitter(messages).getButtons(treeNode as TreeNode).join('') + '</div>' : '';
      case 'comments':
        const comments = this.coreUtilities.unique((<TreeNode> treeNode).unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_COMMENT)).map(comment => {
          const date = new Datum(comment.createdAt);
          const time = date.toPastTime();
          comment.sorting = date.timestamp;
          comment.description = this.coreUtilities.convertNewLineToBr(comment.description);
          comment.technicalDescription = (time.text !== '' ? this.translateService.instant(time.text, { time: time.date }) : time.date);
          if (comment.upload_uri.indexOf('?id=0') !== -1) {
            comment.token = comment.crossReference;
            comment.upload_uri = '/assets/png/default-person.png';
            comment.crossReference = 'External';
          }
          return comment;
        }).sort((a, b) => b.sorting - a.sorting);
        let html = '<div class="comments-list"><ul>';
        const countC = comments.length > 2 ? 2 : comments.length;
        for (let iC = 0; iC < countC; iC++) {
          const comment = comments[iC];
          html += '<li>';
          html += '<div class="comment-image"><img src="' + comment.upload_uri + '" /></div>';
          html += '<div class="comment-content">';
            html += '<div class="comment-content-top">';
              html += '<p>' + comment.crossReference + '</p>';
              html += '<p>' + comment.technicalDescription + '</p>';
            html += '</div>';
            html += '<div class="comment-content-bottom">' + comment.description + '</div>';
          html += '</div>';
          html += '</li>';
        }
        return html + '</ul></div>';
      case 'count':
        if (fieldNode.formFieldCalculation === 'traverser') {
          const traverser = new Traverser().setRelationshipWeights(this.coreService.getRelationshipWeights());
          /* Set the children */
          const children = fieldNode.children !== undefined ? fieldNode.children : [];
          /* Check if there is a column node */
          const columnNode = children.filter(d => d.nodeType === NODES_TYPE_COLUMN)[0];
          /* Check if there is a share node to switch mode to shared */
          const shareTreeNode = children.filter(d => d.nodeType === NODES_TYPE_SHARE)[0];
          if (shareTreeNode !== undefined && shareTreeNode !== null) {
            /* Shared traversal */
            let sharedTraversal: TreeNode[];
            /* Check if the lanes share nodes with the lane from shared nodes */
            const sharedNodes = this.coreUtilities.flatArray(shareTreeNode.children.map(child => {
              /* Get the id from the node itself */
              const count = child.nodestructures.length;
              for (let i = 0; i < count; i++) {
                const nodeStructure = child.nodestructures[i].split(':');
                if (nodeStructure[0] === '' + (<TreeNode>treeNode).modelId && AppGlobal.treeNodes.has(nodeStructure[1])) {
                  sharedTraversal = child.children;
                  const sharedNode = AppGlobal.treeNodes.get(nodeStructure[1]);
                  return sharedNode === undefined ? [] : sharedNode.children;
                }
              }
              return undefined;
            }));
            /* Traversal nodes */
            const regularTraversal = children.filter(d => d.nodeType !== NODES_TYPE_SHARE);
            if (sharedTraversal === undefined || sharedTraversal.length === 0) {
              sharedTraversal = regularTraversal;
            }
            /* Now get the traversal from shared node and selected node */
            const resultA = traverser.addToLanes(sharedTraversal).run(sharedNodes);
            const resultB = traverser.addToLanes(regularTraversal).run((<TreeNode>treeNode).children);
            /* Get the shared elements */
            return this.coreUtilities.arraySharedElements(resultA, resultB).length;
          } else if (columnNode !== undefined && columnNode !== null) {
            const additionalColumns = [];
            const fromLane = new Traverser().setRelationshipWeights(this.coreService.getRelationshipWeights()).setLeafChildren(columnNode.children);
            const fromTreeNode = new Traverser().addToLanes(fromLane).run((treeNode as TreeNode).children);
            const lane = traverser.setLeafParents(traverser.getLeaves(columnNode.children));
            const leafNodes = new Traverser().setRelationshipWeights(this.coreService.getRelationshipWeights()).addToLanes(columnNode.children).run((<TreeNode> treeNode).children);
            const count = leafNodes.length;
            for (let i = 0; i < count; i++) {
              const leafNode = leafNodes[i];
              const hits = new Traverser().setRelationshipWeights(this.coreService.getRelationshipWeights()).addToLanes(lane).run([leafNode], 'parents');
              const id = 'additional-' + leafNode.id;
              additionalColumns.push(<TableColumn> { id: id, label: leafNode.name, editable: false, isNumber: true, formNode: <TreeNode> {
                name: leafNode.name,
                children: [],
                formFieldId: id,
                formFieldControlType: 'textbox',
                formFieldType: 'number',
                form_default_value: 0
              }, controlType: 'textbox' });
              treeNode[id] = this.coreUtilities.arraySharedElements(fromTreeNode, hits).length;
            }
            return additionalColumns;
          } else {
            /* Regular traversing */
            const result = traverser.addToLanes(children).run((<TreeNode> treeNode).children);
            return result.length;
          }
        } else {
          const count = fieldNode.unfilteredChildren.length;
          let amount: TreeNode[];
          for (let i = 0; i < count; i++) {
            const child = fieldNode.unfilteredChildren[i];
            if (isNullOrUndefined(amount)) {
              amount = this.coreUtilities.newTraverser(child, [(<TreeNode> treeNode)]);
            } else {
              if (fieldNode.crossReference === 'OR') {
                amount = amount.concat(this.coreUtilities.newTraverser(child, [(<TreeNode> treeNode)]).filter(newNode => amount.map(a => a.id).indexOf(newNode.id) === -1));
              } else if (amount.length > 0) {
                amount = this.coreUtilities.newTraverser(child, [(<TreeNode> treeNode)]).filter(e => amount.map(a => a.id).indexOf(e.id) !== -1);
              }
            }
          }
          return isNullOrUndefined(amount) ? 0 : amount.length;
        }
      case 'colorlabelprovider':
        const colorLabelProvider = this.coreService.getColorLabelProviderInstance(fieldNode.colorLabelProvider);
        if (colorLabelProvider instanceof ColorlabelproviderServiceStatusfield) {
          return colorLabelProvider.modifyConfiguration(fieldNode).label(<any> treeNode, null);
        } else {
          return colorLabelProvider.label(<any> treeNode, null);
        }
      case 'document':
        if (value === undefined || value === null || value === '') {
          return '';
        } else {
          this.documentsToLoad.push(value);
          return '<i rel="' + value + '" class="fa fa-spinner fa-spin document-loader"></i>';
        }
      default:
        return fieldNode.formFieldType === 'number' ? this.coreTransformer.readableNumber(value, tableConfigurationOptions === undefined ? undefined : tableConfigurationOptions.thousands, tableConfigurationOptions === undefined ? undefined : tableConfigurationOptions.decimal) : value;
    }
  }

  public getConnectedNodes(fieldNode: TreeNode, treeNode: TreeNode) {
    const direction = fieldNode.formFieldId === 'dynamic-parents' || fieldNode.formFieldId === 'dynamic-reassign' ? 'unfilteredParents' : 'unfilteredChildren';
    const onlyDirectConnect = fieldNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_CONNECT).length === 0;
    let filters = [];
    if (this.fieldNodeCache.has(fieldNode.id)) {
      filters = this.fieldNodeCache.get(fieldNode.id);
    } else {
      const count = fieldNode.children.length;
      for (let i = 0; i < count; i++) {
        const child = fieldNode.children[i];
        switch (child.nodeType) {
          case NODES_TYPE_MODEL:
            const models = this.coreService.getInstantModelByModelNode(child).map(model => model.id);
            if (models.length > 0 || !AppGlobal.byToken) {
              filters.push(<CoreFilter> { by: 'modelId', value: models });
            }
            break;
          case NODES_TYPE_NODETYPEGROUP:
            filters.push(<CoreFilter> { by: 'nodeType', value: child.children.map(cchild => cchild.nodeType) });
            break;
        }
      }
      this.fieldNodeCache = this.fieldNodeCache.set(fieldNode.id, filters);
    }
    return onlyDirectConnect ? this.coreService.searchBy({ filters: filters }, treeNode[direction], false) : this.coreService.searchInTree({ filters: filters }, <TreeNode> treeNode, direction);
  }

  public getDropdownOption(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity) {
    /* Get the value */
    const value = this.getValue(fieldNode, treeNode);
    switch (fieldNode.formFieldDropdownValue) {
      case 'humanresources':
      case 'humanresourceimages':
        const humanResource = this.humanResourcesMap.has('' + treeNode.responsibleId) ? this.humanResourcesMap.get('' + treeNode.responsibleId) : null;
        return isNullOrUndefined(humanResource) ? null : <FormOption> { key: humanResource.id, value: humanResource.name, color: humanResource.color, invertedColor: this.coreUtilities.invertColor(humanResource.color, true) };
      case 'statuscolor':
      case 'status':
        const coreStatus: CoreStatusItem = isNullOrUndefined(CoreStatus['' + value]) ? CoreStatus['0'] : CoreStatus['' + value];
        return <FormOption> { key: value, value: coreStatus.text, color: coreStatus.color, invertedColor: this.coreUtilities.invertColor(coreStatus.color, true) };
      case 'level':
        const levelText = isNullOrUndefined(CoreLevel['' + value]) ? CoreLevel['0'] : CoreLevel['' + value];
        return <FormOption> { key: value, value: levelText.text };
      default:
        const option = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION && '' + child.formId === '' + value)[0];
        return !isNullOrUndefined(option) ? <FormOption> { key: option.formId, value: option.name, color: option.color, invertedColor: this.coreUtilities.invertColor(option.color, true) } : null;
    }
  }

  public getSortableValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, autoSumMap: any) {
    /* Auto sum */
    if (autoSumMap !== undefined && autoSumMap[treeNode.id] !== undefined) {
      return autoSumMap[treeNode.id];
    }
    /* Get the value */
    let value = this.getValue(fieldNode, treeNode);
    /* If there is a conversion assigned */
    if (!isNullOrUndefined(fieldNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(fieldNode.fieldConversion, value);
    }

    /* If id is not known */
    if (isNullOrUndefined(value) && fieldNode.formFieldControlType !== 'calculated' && fieldNode.formFieldControlType !== 'dropdown' && fieldNode.formFieldControlType !== 'sublevel' && fieldNode.formFieldControlType !== 'assign' && fieldNode.formFieldControlType !== 'violatedParagraph') {
      return 0;
    }
    /* Switch by control type */
    switch (fieldNode.formFieldControlType) {
      case 'dropdown':
        switch (fieldNode.formFieldId) {
          case 'nodeType':
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            return nodeTypes.length > 0 ? nodeTypes[0].key : 0;
          default:
            switch (fieldNode.formFieldDropdownValue) {
              case 'humanresourceimages':
              case 'humanresources':
                return this.humanResourcesMap.has('' + treeNode.responsibleId) ? this.humanResourcesMap.get('' + treeNode.responsibleId).name : '';
              case 'shape':
                return treeNode[fieldNode.formFieldId];
              default:
                return value;
            }
        }
      case 'calculated':
        return this.getCalculatedValue(fieldNode, treeNode, undefined);
      case 'sublevel':
      case 'sublevelWithName':
        return (<TreeNode> treeNode).subLevel;
      case 'daterange':
      case 'date':
        return new Datum(value).timestamp;
      case 'priority-score':
        return !isNullOrUndefined(this.eisenhowerService) ? this.eisenhowerService.getPriorityScore(<TreeNode> treeNode) : 0;
      default:
        return value;
    }
  }

  public getDropdownOptions(column: TableColumn | FormEntry) {
    return new Promise<{ key: number | string, value: string, color?: string, invertedColor?: string, id?: string }[]>(resolve => {
      const id = !isNullOrUndefined((<TableColumn> column).id) ? (<TableColumn> column).id : (<FormEntry> column).key;
      if (id === 'reference' || id === 'priority-score') {
        this.matrixMap = this.matrixMap.clear();
        const xAxis = this.eisenhowerService.xAxis;
        const yAxis = this.eisenhowerService.yAxis;

        if (!isNullOrUndefined(xAxis) && !isNullOrUndefined(yAxis)) {
          xAxis.children.forEach(xChild => {
            xChild.children.forEach(child => {
              this.matrixMap = this.matrixMap.set(child.name, child);
            });
          });
          yAxis.children.forEach(yChild => {
            yChild.children.forEach(child => {
              this.matrixMap = this.matrixMap.set(child.name, child);
            });
          });
        }
        resolve(this.matrixMap.toArray().sort((a, b) => parseInt(a.name) - parseInt(b.name)).map(matrix => ({ key: matrix.name, value: matrix.name })));
      }
      const formNode = !isNullOrUndefined((<TableColumn> column).formNode) ? (<TableColumn> column).formNode : (<FormEntry> column).entryNode;
      switch (formNode.formFieldDropdownValue) {
        case 'humanresources':
        case 'humanresourceimages':
          this.coreService.getHumanResources().take(1).subscribe(humanResources => {
            resolve(humanResources.map(humanResource => ({ key: humanResource.id, value: humanResource.name })));
          });
          break;
        case 'markets':
          resolve(this.marketsMap.map(market => ({ key: market.crossReference, value: market.name })).toArray());
          break;
        case 'status':
        case 'statuscolor':
          resolve([
            { key: 0, value: 'STATUS.PENDING', color: '#999999', invertedColor: '#ffffff' },
            { key: 100, value: 'STATUS.INDEFINE', color: '#000000', invertedColor: '#ffffff' },
            { key: 110, value: 'STATUS.INDEVELOP', color: '#1099d6', invertedColor: '#ffffff' },
            { key: 120, value: 'STATUS.INVERIFY', color: '#aa008f', invertedColor: '#ffffff' },
            { key: 130, value: 'STATUS.INACCEPT', color: '#edb922', invertedColor: '#000000' },
            { key: 200, value: 'STATUS.COMPLETED', color: '#9ac93e', invertedColor: '#000000' }
          ]);
          break;
        case 'level':
          resolve([
            { key: 0, value: 'LEVEL.OBJECTIVE' },
            { key: 1, value: 'LEVEL.CHALLENGE' },
            { key: 2, value: 'LEVEL.SOLUTION' },
            { key: 3, value: 'LEVEL.DELIVERABLE' },
          ]);
          break;
        case 'nodeType':
          resolve(CoreNodeTypes.map(nodeType => ({ key: nodeType.key,  value: nodeType.label === '' ? '' : this.translateService.instant(nodeType.label) })).sort((a, b) => a.value.localeCompare(b.value)));
          break;
        default:
          resolve(formNode.children.sort((a, b) => a.positionX - b.positionX).filter(child => child.nodeType === NODES_TYPE_FORM_OPTION).map(child => ({
            key: child.formId, value: child.name, color: child.color, invertedColor: child.invertedColor
          })));
          break;
      }
    });
  }

  public convertYearToBucketId(year: number, month?: number) {
    const diff = year - new Datum().year;
    return 'y' + (diff > 0 ? '+' + diff : (diff < 0 ? '-' + diff : ''));
  }

  public convertMomentToBucketId(mom: moment.Moment) {
    return mom.format('YYYY-MM-DD');
  }

  public getValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, tableConfigurationOptions?: TableConfigurationOptions) {
    switch (fieldNode.formFieldId) {
      case 'dynamic-children':
        if (fieldNode.formFieldControlType === 'createNodes') {
          const formNode = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_FORM)[0];
          if (formNode !== undefined) {
            let result = 0;
            const children = (<TreeNode> treeNode).unfilteredChildren;
            const count = children.length;
            for (let i = 0; i < count; i++) {
              const child = children[i];
              result += child[formNode.formFieldId];
            }
            return result;
          }
          return 0;
        }
        if (fieldNode.formFieldControlType === 'node-select') {
          const nodeTypeGroupNode = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_NODETYPEGROUP)[0];
          if (nodeTypeGroupNode !== undefined) {
            const nodeTypes = Set(nodeTypeGroupNode.children.filter(c => c.nodeType).map(c => c.nodeType));
            return (<TreeNode> treeNode).unfilteredChildren.filter(child => nodeTypes.has(child.nodeType)).map(child => child.id);
          }
        }
        return (<TreeNode> treeNode).unfilteredChildren.map(child => child.id);
      case 'dynamic-parents':
      case 'dynamic-reassign':
        if (fieldNode.formFieldControlType === 'node-select') {
          const nodeTypeGroupNode = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_NODETYPEGROUP)[0];
          if (nodeTypeGroupNode !== undefined) {
            const nodeTypes = Set(nodeTypeGroupNode.children.filter(c => c.nodeType).map(c => c.nodeType));
            return ((<TreeNode> treeNode).unfilteredParents === undefined || (<TreeNode> treeNode).unfilteredParents === null ? [] : (<TreeNode> treeNode).unfilteredParents).filter(parent => nodeTypes.has(parent.nodeType)).map(parent => parent.id);
          }
        }
        return (<TreeNode> treeNode).unfilteredParents === undefined || (<TreeNode> treeNode).unfilteredParents === null ? [] : (<TreeNode> treeNode).unfilteredParents.map(parent => parent.id);
      case 'creationDate':
        return (<TreeNode> treeNode).createdAt;
      default:
        /* Check for data source */
        const dataSource = fieldNode.children.filter(child => child.nodeType === NODES_TYPE_DATASOURCE)[0];
        if (dataSource !== undefined) {
          /* Get the data source */
          const source = dataSource.children.filter(child => child.id)[0];
          if (source.nodeType === NODES_TYPE_TRAVERSER) {
            /* Get the data source */
            const sourceNode = new Traverser().addToLanes(source.children).setRevisit(true).setRelationshipWeights(this.coreService.getRelationshipWeights()).run([treeNode as TreeNode])[0];
            if (sourceNode !== undefined && sourceNode !== null) {
              treeNode = sourceNode;
            } else if (tableConfigurationOptions !== undefined && tableConfigurationOptions.noFallback === true) {
              treeNode = undefined;
            }
          }
        }
        if (isNullOrUndefined(treeNode)) {
          return null;
        }
        if (fieldNode.formFieldControlType === 'buckets-sumup') {
          /* Get the buckets for the selected year */
          const bucketDatumStart = this.getBucketDatum(fieldNode.formFieldBucketId, undefined, tableConfigurationOptions.selectedYear).setDate(1).setHours(0).setMinutes(0).setSeconds(0);
          const bucketDatumEnd = bucketDatumStart.clone().addYears(1).addHours(-1).setMinutes(59).setSeconds(59);
          const matches = (!isNullOrUndefined((treeNode as TreeNode).activities) ? (treeNode as TreeNode).activities : []).filter(treeActivity => {
            if (!treeActivity.nodebucket) {
              return false;
            }
            const startDatum = new Datum(treeActivity.start).setHours(0).setMinutes(0).setSeconds(0);
            return bucketDatumStart.lteq(startDatum) && bucketDatumEnd.gteq(startDatum);
          });
          /* Sum up the values */
          let sum = 0;
          const count = matches.length;
          for (let i = 0; i < count; i++) {
            const match = matches[i];
            sum += match[fieldNode.formFieldId];
          }
          return sum;
        }

        if (fieldNode.formFieldControlType === 'coefficient') {
          /* Result */
          let result = 0;
          /* Get the configuration */
          const nodeTypeGroupNode = fieldNode.children.filter(c => c.nodeType === NODES_TYPE_NODETYPEGROUP)[0];
          if (nodeTypeGroupNode !== undefined) {
            const nodeTypes = Set(nodeTypeGroupNode.children.map(c => c.nodeType));
            const nodes = (treeNode as TreeNode).children.filter(child => nodeTypes.has(child.nodeType));
            const count = nodes.length;
            for (let i = 0; i < count; i++) {
              const node = nodes[i];
              if (node[fieldNode.formFieldId] !== null) {
                result += node[fieldNode.formFieldId];
              } else {
                result += 1;
              }
            }
          }
          return result;
        }
        if (fieldNode.formFieldControlType === 'priority-score') {
          return !isNullOrUndefined(this.eisenhowerService) ? this.eisenhowerService.getPriorityScore(<TreeNode> treeNode) : 0;
        }
        if (fieldNode.formBucket) {
         return this.getBucketValue(fieldNode.formFieldId, (<TreeNode> treeNode), fieldNode.formFieldBucketId);
        } else {
          if (fieldNode.formFieldControlType === 'dropdown' && (treeNode[fieldNode.formFieldId] === '' || isNullOrUndefined(treeNode[fieldNode.formFieldId]))) {
            if (treeNode[fieldNode.formFieldId] === null) {
              return fieldNode.formFieldType === 'number' ? parseInt((treeNode as TreeNode).formId) : (treeNode as TreeNode).formId;
            } else {
              return treeNode[fieldNode.formFieldId];
            }
          } else if (fieldNode.formFieldControlType === 'dropdown' && (treeNode[fieldNode.formFieldId] === '' || isNullOrUndefined(treeNode[fieldNode.formFieldId]))) {
            return '0';
          } else {
            return treeNode[fieldNode.formFieldId];
          }
        }
    }
  }

  public getBucketValue(id: string, treeNode: TreeNode, bucketId?: string) {
    return this.getBucket(treeNode, bucketId, undefined, id)[id];
  }

  public setBucketValue(id: string, value: any, treeNode: TreeNode | TreeActivity, bucketId?: string) {
    const bucket: TreeActivity = treeNode.internalType === 'treeActivity' ? (<TreeActivity> treeNode) : this.getBucket((<TreeNode> treeNode), bucketId);
    bucket[id] = value;
    return bucket;
  }

  public getBucket(treeNode: TreeNode, bucketId?: string, createBucket = true, formFieldId?: string) {
    const bucketDatum = this.getBucketDatum(!!bucketId ? bucketId : treeNode.formFieldBucketId);
    const matches = (!isNullOrUndefined(treeNode.activities) ? treeNode.activities : []).filter(treeActivity => {
      if (!treeActivity.nodebucket) {
        return false;
      }
      if (isNullOrUndefined(treeNode.formFieldBucketId) || treeNode.formFieldBucketId === '') {
        const offsetStart = Math.abs(new Datum(treeActivity.start).date.getTimezoneOffset());
        const offsetEnd = Math.abs(new Datum(treeActivity.end).date.getTimezoneOffset());
        const offsetMomentBucket = Math.abs(bucketDatum.date.getTimezoneOffset());
        const momentStart = moment(treeActivity.start).subtract(offsetStart, 'minutes');
        const momentEnd = moment(treeActivity.end).subtract(offsetEnd, 'minutes');
        const momentBucket = moment(bucketDatum.date).subtract(offsetMomentBucket - 1440, 'minutes');
        return momentStart.isSameOrBefore(momentBucket) && momentEnd.isAfter(momentBucket);
      } else {
        return treeActivity.start === bucketDatum.toISOString();
      }
    });
    if (matches.length > 0) {
      const treeActivity = Map(matches[0]).toJS();
      if (matches.length > 1 && formFieldId !== undefined) {
        const count = matches.length;
        let lastValue: any;
        for (let i = 0; i < count; i++) {
          const match = matches[i];
          if (lastValue !== undefined && match[formFieldId] !== lastValue) {
            treeActivity[formFieldId] = 'DATASHEET.GENERAL.DIFFERS';
          }
          lastValue = match[formFieldId];
        }
      }
      return treeActivity;
    } else {
      /* Retry it with the first */
      const datumFirst = this.getBucketDatum(!!bucketId ? bucketId : treeNode.formFieldBucketId, 1);
      const matchesFirst = (!isNullOrUndefined(treeNode.activities) ? treeNode.activities : []).filter(treeActivity => treeActivity.nodebucket && treeActivity.start === datumFirst.toISOString());
      if (matchesFirst.length > 0) {
        return matchesFirst[0];
      }
    }
    if (!createBucket) {
      return undefined;
    }
    return this.coreTransformer.activityToTreeActivity(<Activity> new Activity()
      .set('id', 'bucket-' + treeNode.id)
      .set('name', treeNode.name + '-bucket-0')
      .set('start', bucketDatum.toISOString())
      .set('end', bucketDatum.addMonths(1).toISOString())
      .set('nodebucket', true)
      .set('phantom', true)
      .set('relationships', new ActivityRelationships())
    );
  }

  public getBucketDatum(id: string, date = 3, selectedYear?: number): Datum {
    /* Check straight id match */
    if (id !== undefined && id !== null) {
      const directMatch = id.match(/\d*-\d*-\d*/gm);
      if (directMatch !== null && directMatch.length > 0) {
        return new Datum(id);
      }
    }
    let datum = new Datum().setDay();
    if (selectedYear !== undefined) {
      datum.setYear(selectedYear);
    }
    let quarter = 0;
    if (isNullOrUndefined(id)) {
      return datum;
    }
    let monthMatch = id.match(/(\d+)-(\d+)/);
    if (!isNullOrUndefined(monthMatch)) {
      datum.setDate(1);
      datum.setMonth(parseInt(monthMatch[1]) - 1);
      datum.setYear(parseInt(monthMatch[2]));
      return datum;
    }
    const explicitMatch = id.match(/Y:(\d*)|-M:(\d*)|-D:(\d*)/gm);
    if (!isNullOrUndefined(explicitMatch)) {
      datum.setDate(date);
      datum.setMonth(0);
      if (!isNullOrUndefined(explicitMatch[0])) {
        const yearMatch = explicitMatch[0].match(/\d+/);
        if (!isNullOrUndefined(yearMatch[0]) && yearMatch[0] !== '') {
          datum.setYear(parseInt(yearMatch[0]));
        }
      }
      if (!isNullOrUndefined(explicitMatch[1])) {
        monthMatch = explicitMatch[1].match(/\d+/);
        if (!isNullOrUndefined(monthMatch[0]) && monthMatch[0] !== '') {
          datum.setMonth(parseInt(monthMatch[0]));
        }
      }
      if (!isNullOrUndefined(explicitMatch[2])) {
        const dayMatch = explicitMatch[2].match(/\d+/);
        if (!isNullOrUndefined(dayMatch[0]) && dayMatch[0] !== '') {
          datum.setDate(parseInt(dayMatch[0]));
        }
      }
      return datum;
    }
    const quarterMatch = id.match(/Q(\d)-/);
    if (!isNullOrUndefined(quarterMatch)) {
      quarter = (parseInt(quarterMatch[1]) - 1) * 3;
      id = id.replace(quarterMatch[0], '');
    }
    const match = id.match(/(\w)(\W)(\d)|(\w)/);
    if (!isNullOrUndefined(match)) {
      const operator = match[2];
      const addition = isNullOrUndefined(match[3]) ? 0 : parseInt(match[3]);
      const key = (isNullOrUndefined(match[1]) ? match[0] : match[1]).toLowerCase();
      switch (key) {
        case 'y':
          datum = datum.setMonth(quarter).setDate(date);
          if (date === 1) {
            datum = datum.setHours(12);
          }
          if (!isNullOrUndefined(operator) && !isNullOrUndefined(addition)) {
            datum = datum.setYear(operator === '+' ? (datum.year + addition) : (datum.year - addition));
          }
          break;
      }
    }
    return datum;
  }

  public getCalculatedValue(fieldNode: TreeNode, treeNode: TreeNode | TreeActivity, fields?: any) {
    switch (fieldNode.formFieldCalculation) {
      case 'roi':
        let benefit = fieldNode.formBucket ? this.getBucketValue('benefitBudget', (<TreeNode> treeNode)) : treeNode.benefitBudget;
        if (!isNullOrUndefined(fields) && !isNullOrUndefined(fields.benefit)) {
          benefit = fields.benefit.formBucket ? this.getBucketValue(fields.benefit.formFieldId, (<TreeNode> treeNode)) : treeNode[fields.benefit.formFieldId];
        }
        let cost = fieldNode.formBucket ? this.getBucketValue('costBudget', (<TreeNode> treeNode)) : treeNode.costBudget;
        if (!isNullOrUndefined(fields) && !isNullOrUndefined(fields.cost)) {
          cost = fields.cost.formBucket ? this.getBucketValue(fields.cost.formFieldId, (<TreeNode> treeNode)) : treeNode[fields.cost.formFieldId];
        }
        if (cost === 0) {
          cost = 1;
        }
        return parseFloat(this.coreTransformer.readableNumber(benefit / cost));
      case 'strategicclassification':
        const classifications = this.coreService.searchBy({ filters: [{ by: 'level', value: 0 }, { by: 'subLevel', value: 0 }] }, (<TreeNode> treeNode).parents, true, 'parents');
        return classifications.length > 0 ? classifications[0].name : treeNode.name;
      case 'challenge':
        const challenge = this.coreService.searchBy({ filters: [{ by: 'level', value: 1 }] }, (<TreeNode> treeNode).parents, true, 'parents').sort((a, b) => a.subLevel - b.subLevel);
        return challenge.length > 0 ? challenge[0].name : '';
      case 'challenge2nd':
        const challenge2nd = this.coreService.searchBy({ filters: [{ by: 'level', value: 1 }] }, (<TreeNode> treeNode).parents, true, 'parents').sort((a, b) => a.subLevel - b.subLevel);
        if (challenge2nd.length > 0) {
          const firstSubLevel = challenge2nd[0].subLevel;
          const count2 = challenge2nd.length;
          let secondSubLevel: TreeNode;
          for (let i = 0; i < count2; i++) {
            const c = challenge2nd[i];
            if (c.subLevel !== firstSubLevel) {
              secondSubLevel = c;
              break;
            }
          }
          if (!isNullOrUndefined(secondSubLevel)) {
            return secondSubLevel.name;
          }
        }
        return '';
      case 'line-design':
        let design = 'Single';
        if (treeNode !== undefined) {
          const positionNodes = (<TreeNode> treeNode).children;
          const machineTypes = [];
          const count = positionNodes.length;
          for (let i = 0; i < count; i++) {
            const positionNode = positionNodes[i];
            const machineType = positionNode.parents.filter(parent => parent.nodeType === NODES_TYPE_MACHINE_TYPE)[0];
            if (!isNullOrUndefined(machineType)) {
              if (machineTypes.indexOf(machineType.id) !== -1) {
                design = 'Double';
                break;
              }
              machineTypes.push(machineType.id);
            }
          }
        }
        return design;
      case 'calculateCO2Scope3_3':
        const heizöl = treeNode['heizöl'] * treeNode['heizölCoeff'];
        const percentageErdgas = 100 - treeNode['percentageBiogas'];
        const erdgas = (treeNode['gas'] * (percentageErdgas / 100)) * treeNode['erdgasCoeff'];
        const biogas = (treeNode['gas'] * (treeNode['percentageBiogas'] / 100)) * treeNode['biogasCoeff'];
        const strom =  treeNode['strom'] * treeNode['stromCoeff'];
        const ökostrom = treeNode['ökostrom'] * treeNode['ökostromCoeff'];
        const holzPellets = treeNode['holzPellets'] * treeNode['holzPelletsCoeff'];
        const pkw = (treeNode['pkw'] * treeNode['pkwKm'] * treeNode['pkwCoeff']) + (treeNode['elektroPkw'] * treeNode['pkwKm'] * treeNode['elektroPkwCoeff']) + (treeNode['wasserPkw'] * treeNode['pkwKm'] * treeNode['wasserPkwCoeff']);
        const lkw = (treeNode['lkw'] * treeNode['lkwKm'] * treeNode['lkwCoeff']) + (treeNode['elektroLkw'] * treeNode['lkwKm'] * treeNode['elektroLkwCoeff']) + (treeNode['wasserLkw'] * treeNode['lkwKm'] * treeNode['wasserLkwCoeff']);
        const fernwärme = treeNode['fernwärme'] * treeNode['fernwärmeCoeff'];

        return heizöl + erdgas + biogas + strom + ökostrom + holzPellets + pkw + lkw + fernwärme;
      default:
        /* Check if there is a configuration */
        if (!isNullOrUndefined(fieldNode.form_default_value) && fieldNode.form_default_value !== '') {
          try {
            if (!isNullOrUndefined(fieldNode.crossReference) && fieldNode.crossReference !== '') {
              const decimalFigures = fieldNode.crossReference.length;
              const calculatedResult = this.calculationService.calculateByConfigurationNode(fieldNode, treeNode);
              const factor = 10 ** decimalFigures;

              return Math.round(calculatedResult * factor) / factor + 'a';
            } else {
            return this.calculationService.calculateByConfigurationNode(fieldNode, treeNode);
          }
          } catch (e) {
            console.warn(e);
            return 0;
          }
        }
        return 0;
    }
  }

  public getCalculatedValueByEntry(entry: FormEntry, treeNode: TreeNode) {
    switch (entry.calculation) {
      case 'roi':
        const benefit = entry.nodeBucket ? this.getBucketValue('benefitBudget', treeNode) : treeNode.benefitBudget;
        let cost = entry.nodeBucket ? this.getBucketValue('costBudget', treeNode) : treeNode.costBudget;
        if (cost === 0) {
          cost = 1;
        }
        return parseFloat(this.coreTransformer.readableNumber(benefit / cost));
      case 'assignedcapacity':
        return this.assignedCapacity(entry, treeNode);
      case 'aggregationsub':
        return this.aggregationSub(entry, treeNode);
      case 'aggregation':
        return this.aggregateChildren(entry, treeNode);
      default:
    }
  }

  public getEntryValue(entry: FormEntry, treeNode: TreeNode) {
    /* If source node type is set */
    if (!isNullOrUndefined(entry.sourceNodeType)) {
      const sourceNode = treeNode.unfilteredChildren.filter(child => child.nodeType === entry.sourceNodeType)[0];
      if (!isNullOrUndefined(sourceNode)) {
        treeNode = sourceNode;
      }
    }
    /* Get the value */
    const key = entry.key.split(':')[0];
    let value = entry.nodeBucket ? this.getBucketValue(key, treeNode, entry.entryNode.formFieldBucketId) : treeNode[key];
    if (value === DynamicFormComponent.DIFFERS) {
      return DynamicFormComponent.DIFFERS;
    }

    /* If there is a conversion assigned */
    if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(entry.entryNode.fieldConversion)) {
      value = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, value);
    }
    /* If value is undefined */
    if (isNullOrUndefined(value) && entry.controlType !== 'calculated' && entry.controlType !== 'violatedParagraph' && entry.controlType !== 'node-select' && entry.controlType !== 'comments') {
      return '';
    }
    /* Switch by control type */
    switch (entry.controlType) {
      case 'dropdown':
        switch (key) {
          case 'nodeType':
            const nodeTypes = CoreNodeTypes.filter(nodeType => nodeType.key === value);
            if (entry.entryNode.formFieldDropdownValue !== 'nodeType') {
              return nodeTypes.length > 0 ? this.translateService.instant(nodeTypes[0].label) : '';
            } else {
              return nodeTypes.length > 0 ? nodeTypes[0].key : '';
            }
          case 'colorLabelProvider':
            const clps = this.coreService.getColorLabelProviders().filter(clp => clp.key === value);
            if (entry.entryNode.formFieldDropdownValue === 'colorLabelProviders') {
                return clps.length > 0 ? clps[0].key : '';
            }
            break;
          case 'widget':
            const widgets = CoreWidgets.filter(widget => widget.key === value);
            if (entry.entryNode.formFieldDropdownValue === 'colorLabelProviders') {
                return widgets.length > 0 ? widgets[0].key : '';
            }
            break;
          case 'responsible':
            return <HumanResource>{id: value};
        }
        return value;
      case 'violatedParagraph':
        return !isNullOrUndefined(this.additionalInfos) && !isNullOrUndefined(this.additionalInfos['violatedParagraphs']) ? this.additionalInfos['violatedParagraphs'] : [];
      case 'calculated':
        value = this.getCalculatedValueByEntry(entry, treeNode);
        /* If there is a conversion assigned */
        if (!isNullOrUndefined(entry.entryNode.fieldConversion) && entry.calculation !== 'aggregationsub') {
          value = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, value);
        }
        return value;
      case 'node-select':
        const direction = (entry.entryNode.formFieldId === 'dynamic-parents' || entry.entryNode.formFieldId === 'dynamic-reassign') ? 'unfilteredParents' : 'unfilteredChildren';
        const onlyDirectConnect = entry.entryNode.unfilteredChildren.filter(child => child.nodeType === NODES_TYPE_CONNECT).length === 0;
        let filters = [];
        if (this.fieldNodeCache.has(entry.entryNode.id)) {
          filters = this.fieldNodeCache.get(entry.entryNode.id);
        } else {
          const count = entry.entryNode.children.length;
          for (let i = 0; i < count; i++) {
            const child = entry.entryNode.children[i];
            switch (child.nodeType) {
              case NODES_TYPE_MODEL:
                filters.push(<CoreFilter> { by: 'modelId', value: this.coreService.getInstantModelByModelNode(child).map(model => model.id) });
                break;
              case NODES_TYPE_NODETYPEGROUP:
                filters.push(<CoreFilter> { by: 'nodeType', value: child.children.map(cchild => cchild.nodeType) });
                break;
            }
          }
          this.fieldNodeCache = this.fieldNodeCache.set(entry.entryNode.id, filters);
        }
        const connectedNodes = onlyDirectConnect ? this.coreService.searchBy({ filters: filters }, treeNode[direction], false) : this.coreService.searchInTree({ filters: filters }, <TreeNode> treeNode, direction);
        return { selected: connectedNodes, initialSelected: connectedNodes, touched: false, element: treeNode };
      case 'comments':
        return treeNode.children === undefined ? '' : treeNode.children.filter(child => child.nodeType === NODES_TYPE_COMMENT);
      default:
        return value;
    }
  }

  public getEntriesKeyMap(formElements: FormElement[], entriesKeyMap = Map<string, FormEntry>()): Map<string, FormEntry> {
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      const formElement = formElements[i];
      if (!isNullOrUndefined(formElement.entry.controlType)) {
        if (formElement.entry.controlType === 'datestack') {
          const countDateStack = formElement.entry.entryNode.children.length;
          for (let ids = 0; ids < countDateStack; ids++) {
            const child = formElement.entry.entryNode.children[ids];
            entriesKeyMap = entriesKeyMap.set(child.formFieldId + ':' + child.id, this.buildFormEntry(child).entry);
          }
          continue;
        }
        // if (formElement.entry.controlType === 'odin-factsheet') {
        //   continue;
        // }
        entriesKeyMap = entriesKeyMap.set(formElement.entry.key, formElement.entry);
        if (formElement.entry.controlType === 'questionnaire') {
          const count_ = formElement.entry.entryNode.children.length;
          for (let i_ = 0; i_ < count_; i_++) {
            const child = formElement.entry.entryNode.children[i_];
            switch (child.formFieldControlType) {
              case 'textbox':
                entriesKeyMap = entriesKeyMap.set(child.formFieldId, this.buildFormEntry(child).entry);
                break;
              default:
                const count2 = child.children.length;
                for (let j = 0; j < count2; j++) {
                  const cchild = child.children[j];
                  entriesKeyMap = entriesKeyMap.set(cchild.formFieldId, this.buildFormEntry(cchild).entry);
                }
                break;
            }
          }
        }
        if (formElement.entry.controlType === 'checkChildren') {
          entriesKeyMap = entriesKeyMap.set('checkChildren' + ':' + formElement.entry.key, formElement.entry);
        }
        if (formElement.entry.controlType === 'coefficient') {
          entriesKeyMap = entriesKeyMap.set('coefficient' + ':' + formElement.entry.key, formElement.entry);
          entriesKeyMap = entriesKeyMap.remove(formElement.entry.key);
        }
        if (formElement.entry.controlType === 'text-marker') {
          entriesKeyMap = entriesKeyMap.set('text-marker' + ':' + formElement.entry.key, formElement.entry);
        }

      }
      if (!isNullOrUndefined(formElement.children)) {
        entriesKeyMap = this.getEntriesKeyMap(formElement.children, entriesKeyMap);
      }
    }
    return entriesKeyMap;
  }

  public buildFormEntry(treeNode: TreeNode, readonly = false, editorConfig?: any) {
    let formFieldId = treeNode.formFieldId;
    if (treeNode.formFieldControlType === 'comments') {
      formFieldId = 'comments';
    }
    const entry: any = { entry: {
        key: formFieldId + ':' + treeNode.id,
        type: treeNode.formFieldType,
        controlType: treeNode.formFieldControlType,
        label: treeNode.name,
        nodeBucket: treeNode.formBucket,
        editable: readonly === true ? false : treeNode.formFieldEditable,
        required: treeNode.obligatory,
        additionalLabel: treeNode.form_default_value,
        entryNode: treeNode,
        sourceNodeType: undefined
      }};
    if (!isNullOrUndefined(editorConfig) && !isNullOrUndefined(editorConfig[treeNode.formFieldId])) {
      entry.entry.editorConfig = editorConfig[treeNode.formFieldId];
    }
    const childSourceNode = treeNode.children.filter(child => child.nodeType === NODES_TYPE_CHILD)[0];
    if (!isNullOrUndefined(childSourceNode) && childSourceNode.children.length > 0) {
      entry.entry.sourceNodeType = childSourceNode.children[0].nodeType;
    }
    if (treeNode.formFieldControlType === 'dropdown') {
      if (treeNode.formFieldDropdownValue === '' || treeNode.formFieldDropdownValue === 'shape') {
        entry.entry.options = treeNode.children.filter(optionNode => optionNode.nodeType === NODES_TYPE_FORM_OPTION).sort((a, b) => this.coreUtilities.sort(a.name, b.name, true)).sort((a, b) => a.positionX - b.positionX).map(optionNode => {
          const option: FormOption = { key: optionNode.formId, value: optionNode.name, optionNode: optionNode };
          if (optionNode.color !== '') {
            option.background = optionNode.color;
            option.color = this.coreUtilities.invertColor(option.background, true);
          }
          option.hidden = optionNode.hideWidget;
          return option;
        });
        if (entry.entry.options.length > 0 && treeNode.formFieldShape !== 'no-null') {
          if (entry.entry.type === 'string' && entry.entry.options.filter(option => option.key === '').length > 0) {
          } else if (entry.entry.type !== 'string' && entry.entry.options.filter(option => option.key === 0).length > 0) {
          } else {
            entry.entry.options.unshift({ key: null, value: '' });
          }
        }
      } else {
        entry.entry.source = treeNode.formFieldDropdownValue;
        if (treeNode.formFieldDropdownValue === 'nodes') {
          entry.entry.type = treeNode.nodeType;
        }
      }
    }
    if (entry.entry.controlType === 'calculated') {
      entry.entry.key = treeNode.formFieldCalculation;
      entry.entry.calculation = treeNode.formFieldCalculation;
      entry.entry.value = this.getCalculatedValueByEntry(entry.entry, treeNode);
    }
    if (entry.entry.controlType === 'children') {
      if (isNullOrUndefined(entry.entry.source)) {
        entry.entry.source = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.buildFormEntries(treeNode) }] };
      }
    }
    return entry;
  }

  public buildFormEntries(treeNode: TreeNode) {
    return treeNode.children.sort((a, b) => a.positionX - b.positionX).map(childTreeNode => this.buildFormEntry(childTreeNode));
  }

  public buildFormEntriesFromTreeNodes(treeNodes: TreeNode[], readonly = false, editorConfig?: any) {
    return treeNodes.map(treeNode => this.buildFormEntry(treeNode, readonly, editorConfig));
  }

  public toFormGroup(entries: Array<any>, element: any) {
    let formGroup = new FormGroup({});
    if (!isNullOrUndefined(entries)) {
      formGroup = this.buildFormRec(entries, formGroup, element);
    }
    return formGroup;
  }

  public getDeltaFromFormGroup(formGroup: FormGroup, treeNode: TreeNode): Map<string, TreeNode> {
    let delta = Map<string, any>();
    Map(formGroup.controls['header-1'].value).forEach((value, key: string) => {
      key = key.split(':')[0];
      delta = delta.set(key, value);
    });
    return delta;
  }

  public getFormResultFromFormElement(formElement: any, formNode: TreeNode, treeNode: TreeNode): CoreMultiTransfer {
    const formResult = <FormResult> { delta: Map<string, any>(), formGroup: null };
    /* Get the form element */
    const element = jQuery(formElement);
    /* Get the form configuration */
    const entries = this.buildFormEntries(formNode);
    const formInterface = <FormInterface> { tabs: entries };
    const count = entries.length;
    for (let i = 0; i < count; i++) {
      const entry = entries[i];
      const oldValue = this.getEntryValue(entry.entry, treeNode);
      let newValue = element.find('[name="' + entry.entry.key + '"]').val();
      if (isNullOrUndefined(newValue)) {
        newValue = element.val();
      }
      if (!isNullOrUndefined(entry.entry.entryNode) && !isNullOrUndefined(entry.entry.entryNode.fieldConversion)) {
        newValue = this.coreTransformer.invertConvertValue(entry.entry.entryNode.fieldConversion, newValue);
      }
      if (!isNullOrUndefined(newValue) && newValue !== oldValue) {
        formResult.delta = formResult.delta.set(entry.entry.key, newValue);
      }
    }
    return this.getCoreTransferFromFormResult(formResult, formInterface, treeNode);
  }

  public getFormHtml(formElements: FormElement[], treeNode: TreeNode): string {
    let html = '<form data-id="' + treeNode.id + '">';
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      /* Form element */
      const formElement = formElements[i];
      /* Form bucket */
      let parentTreeNodeFromBucket = this.coreUtilities.getParentTreeNodeFromBucket(treeNode);
      if (formElement.entry.key === 'aggregation') {
        parentTreeNodeFromBucket = treeNode;
      }
      /* Get the value */
      let value = this.getEntryValue(formElement.entry, parentTreeNodeFromBucket);
      if (isNullOrUndefined(value) || (value === 0 && formElement.entry.calculation !== 'aggregationsub')) {
        value = '';
      }
      if (isNumber(value)) {
        value = parseFloat(parseFloat('' + value).toFixed(2));
      }
      switch (formElement.entry.controlType) {
        case 'textbox':
          /* Build the html */
          if (formElement.entry.editable) {
            /* Min and max values -> Question value on a treeNode*/
            let valueConstraints = '';
            if (!isNullOrUndefined(formElement.entry.entryNode.costDescription) && formElement.entry.entryNode.costDescription) {
              valueConstraints = formElement.entry.entryNode.costDescription;
              const regexpSize = /([0-9]+[,.][0-9]+):([0-9]+)/;
              const match = valueConstraints.match(regexpSize);
              if (!isNullOrUndefined(match) && Array.isArray(match) && match.length === 3) {
                valueConstraints = `min="${parseFloat(match[1])}" max="${parseInt(match[2])}"`;
              } else {
                valueConstraints = '';
              }

            }
            html += '<div class="input-group">';
            html += '<input type="number" step=".1" name="' + formElement.entry.key + '" placeholder="' + formElement.entry.label + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" ' + valueConstraints + ' />';
            if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
              html += '<div class="input-group-append">';
              html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
              html += '</div>';
            }
            html += '</div>';
          } else if (treeNode.nodeType === NODES_TYPE_HUMANRESOURCE) {
            html += '<input type="hidden" name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" /><p>' + value + '</p>';
          } else {
            html += '<div class="input-group">';
            html += '<input type="number" step=".01" disabled="disabled" name="' + formElement.entry.key + '" placeholder="' + formElement.entry.label + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" />';
            if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
              html += '<div class="input-group-append">';
              html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
              html += '</div>';
            }
            html += '</div>';
          }
          break;
        case 'calculated':
          html += '<div class="input-group">';
          html += '<input disabled="disabled" name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" style="background: #e7f2ba;" />';
          if (!isNullOrUndefined(formElement.entry.additionalLabel) && formElement.entry.additionalLabel !== '') {
            html += '<div class="input-group-append">';
            html += '<span class="input-group-text">' + formElement.entry.additionalLabel + '</span>';
            html += '</div>';
          }
          html += '</div>';
          break;
      }
    }
    html += '</form>';
    return count === 0 ? '' : html;
  }

  public getFormTabsHtml(formElements: FormElement[], treeNode: TreeNode): string {
    let html = '<div class="form form-inline">';
    const count = formElements.length;
    let previousValue: number;
    for (let i = 0; i < count; i++) {
      /* Form element */
      const formElement = formElements[i];
      /* Styles */
      const styles = [];
      /* Set the width */
      styles.push('width: ' + (!isNullOrUndefined(formElement.entry.entryNode.formFieldWidth) ? formElement.entry.entryNode.formFieldWidth : ''));
      /* Get the value */
      let value = this.getEntryValue(formElement.entry, treeNode);
      if (!isNullOrUndefined(previousValue) && previousValue < value) {
        styles.push('background: red');
        styles.push('color: white');
      }
      if (isNullOrUndefined(value) || value === 0) {
        value = '';
      }
      html += '<div class="input-group">';
      if (i < count - 1) {
        html += '<div class="input-group-prepend"><span class="input-group-text">' + formElement.entry.label + '</span></div>';
      }
      const disabled = formElement.entry.controlType === 'calculated' || !formElement.entry.editable ? ' disabled="disabled" ' : '';
      html += '<form data-id="' + treeNode.id + '"><input style="' + styles.join(';') + '" ' + disabled + 'name="' + formElement.entry.key + '" data-id="' + treeNode.id + '" data-value="' + value + '" value="' + value + '" /></form>';
      if (i === count - 1) {
        html += '<div class="input-group-append"><span class="input-group-text">' + formElement.entry.label + '</span></div>';
      }
      html += '</div>';
      previousValue = parseFloat('' + value);
    }
    html += '</div>';
    return html;
  }

  private buildFormRec(properties: Array<any>, formGroup: FormGroup, element: any): FormGroup {
    if (isNullOrUndefined(properties)) {
      return formGroup;
    }
    properties.forEach((property) => {
      const entry = property.entry;
      const children = property.children;
      if (!isNullOrUndefined(children)) {
        const subGroup = this.buildFormRec(children, new FormGroup({}), element);
        formGroup.setControl(entry.key, subGroup);
      } else {
        const validations = entry.required ? [Validators.required] : [];
        if (entry.required && entry.controlType === 'dropdown') {
          validations.push(Validators.min(1));
        }
        if (entry.required && entry.controlType === 'checkbox') {
          validations.push(Validators.requiredTrue);
        }
        if (entry.required && entry.controlType === 'textbox' && entry.type === 'number') {
          validations.push((control) => '' + control.value === '0' ? [Map().set(entry.key, 'Zero is not allowed').toJS()] : []);
        }
        if (entry.required && entry.controlType === 'node-select') {
          validations.push((control) => {
            let selected = [];
            if (!isNullOrUndefined(control.value) && control.value !== '' && control.value !== 0) {
              selected = control.value.selected;
            }
            return selected.length > 0 ? [] : [Map().set(entry.key, 'Nothing selected').toJS()];
          });
        }
        formGroup.setControl(entry.key, new FormControl(entry.value || '', validations));
        /* Check if there is an element */
        if (!isNullOrUndefined(element)) {
          /* Get the value */
          let value = this.getEntryValue(entry, element);
          if (entry.controlType === 'hidden' && value === '' && entry.additionalLabel !== '') {
            value = entry.additionalLabel;
          }
          const values = {};
          values[entry.key] = value;
          /* Patch the value */
          formGroup.patchValue(values);
          formGroup.controls[entry.key].updateValueAndValidity();
        }
      }
    });
    return formGroup;
  }

  public aggregateChildren(entry: FormEntry, treeNode: TreeNode, date?: Datum, filtered = false, result = 0, ids = []): number {
    if (ids.indexOf(treeNode.id) !== -1) {
      return result;
    }
    ids.push(treeNode.id);
    const relationships = this.coreService.getInstantRelationships({ filters: [{ by: 'parentId', value: treeNode.id }] });
    if (relationships.length > 0) {
      let relMap = Map<string, number>();
      let count = relationships.length;
      for (let i = 0; i < count; i++) {
        const relationship = relationships[i];
        relMap = relMap.set(relationship.parentId + ':' + relationship.childId, relationship.weight);
      }
      const children = filtered ? [...treeNode.children] : [...treeNode.unfilteredChildren];
      let childrenNames = ' : ';
      children.forEach(x => childrenNames += x.name + ', ');
      count = children.length;
      for (let i = 0; i < count; i++) {
        const child = children[i];
        const relId = treeNode.id + ':' + child.id;
        const weight = parseFloat('' + (relMap.has(relId) ? relMap.get(relId) : 0));
        if (child.nodeType === NODES_TYPE_HUMANRESOURCE || child.nodeType === NODES_TYPE_REQUIREMENT) {
          const bucketDatum = !isNullOrUndefined(date) ? <string> date.toString('MY') : date;
          const childValue = entry.nodeBucket ? parseFloat(this.getBucketValue(entry.entryNode.formFieldId, this.coreUtilities.getParentTreeNodeFromBucket(child), bucketDatum)) : this.getValue(entry.entryNode, this.coreUtilities.getParentTreeNodeFromBucket(child));
          result += (childValue * (weight / 100));
        }
        /* Continue */
        if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(children) && !(children.length === 0 || children.filter(c => c.nodeType === child.nodeType).length === 0)) {
          result = this.aggregateChildren(entry, child, date, filtered, result, ids);
        }
      }
      return parseFloat(parseFloat('' + result).toFixed(2));
    }
    return result;
  }

  public assignedCapacity(entry: FormEntry, treeNode: TreeNode): number {
    const capacities = this.coreService.searchBy({ filters: [{ by: 'nodeType', value: NODES_TYPE_CAPACITY }] }, treeNode.unfilteredChildren, true);
    let capacity = 0;
    const count = capacities.length;
    for (let i = 0; i < count; i++) {
      capacity += this.aggregateChildren(entry, capacities[i]);
    }
    return capacity;
  }

  public aggregationSub(entry: FormEntry, treeNode: TreeNode) {
    let aggregatedValue = this.aggregateChildren(entry, treeNode);
    if (!isNullOrUndefined(entry.entryNode) && !isNullOrUndefined(entry.entryNode.fieldConversion)) {
      aggregatedValue = this.coreTransformer.convertValue(entry.entryNode.fieldConversion, aggregatedValue);
    }
    /* Search for connected requirements */
    const requirements = treeNode.parents.filter(parent => parent.nodeType === NODES_TYPE_REQUIREMENT);
    const count = requirements.length;
    let minus = 0;
    const ids = [];
    for (let i = 0; i < count; i++) {
      const requirement = requirements[i];
      if (ids.indexOf(requirement.id) === -1) {
        entry.controlType = 'textbox';
        entry.key = entry.entryNode.formFieldId;
        minus += this.getEntryValue(entry, requirement);
        ids.push(requirement.id);
      }
    }
    const result = aggregatedValue - minus;
    return result < 0 ? 0 : result;
  }

  public getFormEntryByKey(formElements: FormWidgetFormElement[], key: string) {
    let result: FormWidgetFormElement;
    const count = formElements.length;
    for (let i = 0; i < count; i++) {
      const formElement = formElements[i];
      if (!isNullOrUndefined(formElement.entry) && formElement.entry.entry.key === key) {
        result = formElement;
        break;
      }
      if (!isNullOrUndefined(formElement.children)) {
        result = this.getFormEntryByKey(formElement.children, key);
        if (!isNullOrUndefined(result)) {
          break;
        }
      }
    }
    return result;
  }

  /**
   * Merge tree nodes
   * @param treeNodes
   */
  public mergeTreeNodes(treeNodes: TreeNode[]): TreeNode {
    const mergedNode: TreeNode = <TreeNode> {};
    let activities: TreeActivity[] = [];
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      activities = activities.concat(treeNode.activities);
      const keys = Object.keys(treeNode);
      const countK = keys.length;
      for (let k = 0; k < countK; k++) {
        const key = keys[k];
        if (key === 'parents' || key === 'unfilteredParents' || key === 'children' || key === 'unfilteredChildren') {
          if (isNullOrUndefined(mergedNode[key])) {
            mergedNode[key] = [];
          }
          mergedNode[key] =  mergedNode[key].filter(e => treeNode[key].map(a => a.name).indexOf(e.name) === -1).concat(treeNode[key]);
        } else if (key === 'parentIds') {
          if (isNullOrUndefined(mergedNode.mergedParents)) {
            mergedNode.mergedParents = [];
          }
          mergedNode.mergedParents.push(treeNode.parents);
        } else if (key === 'childIds') {
          if (isNullOrUndefined(mergedNode.mergedChildren)) {
            mergedNode.mergedChildren = [];
          }
          mergedNode.mergedChildren.push(treeNode.children);
        } else if (key === 'id') {
          if (count > 1) {
            mergedNode[key] = DynamicFormComponent.DIFFERS;
          } else {
            mergedNode[key] = treeNode[key];
          }
          if (i === 0) {
            mergedNode.ids = [];
          }
          mergedNode.ids.push(treeNode[key]);
        } else {
          if (!mergedNode.hasOwnProperty(key)) {
            mergedNode[key] = treeNode[key];
          }
          if (mergedNode[key] !== treeNode[key] && key !== 'relationships' && key !== 'activities') {
            mergedNode[key] = DynamicFormComponent.DIFFERS;
          }
        }
      }
    }
    /* Merge activities as well */
    if (!isNullOrUndefined(mergedNode.activities)) {
      mergedNode.activities = this.coreUtilities.unique(mergedNode.activities.concat(activities)).filter(activity => !isNullOrUndefined(activity));
    }
    return mergedNode;
  }

  /**
   * Merge tree activities
   * @param treeActivities
   */
  private mergeTreeActivities(treeActivities: TreeActivity[]): TreeActivity[] {
    let mergedActivities = Map<string, TreeActivity>();
    const result: TreeActivity[] = [];
    const count = treeActivities.length;
    for (let i = 0; i < count; i++) {
      const treeActivity = treeActivities[i];
      if (isNullOrUndefined(treeActivity.start) || '' + treeActivity.start === '' || '' + treeActivity.start === '0') {
        result.push(treeActivity);
        continue;
      }
      if (!mergedActivities.has(treeActivity.start)) {
        mergedActivities = mergedActivities.set(treeActivity.start, treeActivity);
      } else {
        const mergedTreeActivity = mergedActivities.get(treeActivity.start);
        const keys = Object.keys(treeActivity);
        const countK = keys.length;
        for (let k = 0; k < countK; k++) {
          const key = keys[k];
          if (key === 'id') {
            continue;
          }
          if (!mergedTreeActivity.hasOwnProperty(key)) {
            mergedTreeActivity[key] = treeActivity[key];
          }
          if (mergedTreeActivity[key] !== treeActivity[key] && key !== 'relationships' && key !== 'activities') {
            mergedTreeActivity[key] = DynamicFormComponent.DIFFERS;
          }
        }
      }
    }
    return result.concat(mergedActivities.toArray());
  }

}
